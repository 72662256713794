<template>
    
    <div class="h-6 flex flex-row justify-between items-center z-20">
        <All model="Dimensionfiltertops" :immediate="true" v-slot="{data: dimensions}">
            <div class="h-full w-full relative">
                <div class="absolute top-0 left-0 w-full z-30 bg-videowall_gray cursor-pointer overflow-hidden flex flex-col shadow_box"
                    :class="{'h-6 rounded-xl': !opendimensions, 'h-auto rounded-xl': opendimensions}" style="transition: all .3s;">
                    <div class="h-6 w-full flex flex-row justify-between items-center px-2 border-b border-videowall_box rounded-xl shadow bg-red relative"
                        @click="opendimensions = !opendimensions">
                        <span class="text-sm text-white font-semibold">{{ translate(category_filter_name) }}</span>
                        <i class="mdi mdi-menu-down text-white absolute top-0 right-0 mr-2"></i>
                    </div>
                    <div v-if="opendimensions" class="flex-1 overflow-auto videowall_scrollbar">
                        <div v-for="(el, index) in dimensions" :key="index" v-if="el.Top" class="h-6 w-full mt-1 flex flex-row justify-center items-center hover:bg-red_transparent"
                            @click="changeOption(el)">
                            <span class="text-sm text-white" :class="{'font-bold': category_filter_name === el.Name}">{{ translate(el.Name) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </All>
    </div>

</template>

<script>
import { All } from '@/api/components';
import { state,actions } from '@/store';

export default {
    components: {
        All
    },
    data() {
        return {
            opendimensions: false,
            category_filter: null,
            category_filter_name: null
        }
    },
    methods: {
        translate(word) {
            switch (word) {
                case 'Paises':
                    return this.$t("countries")
                    break;
                case 'Todos los continentes':
                    return this.$t("allContinents")
                    break;
                case 'Provincias de España':
                    return this.$t("spanishProvinces")
                    break;
                case 'Formatos':
                    return this.$t("formats")
                    break;
                case 'Clientes':
                    return this.$t("customers")
                    break;
                case 'Continentes':
                    return this.$t("continents")
                    break;
                case 'SuperFamilias':
                    return this.$t("superFamily")
                    break;
                case 'Colores':
                    return this.$t("colors")
                    break;
                case 'Modelos':
                    return this.$t("models")
                    break;
                default:
                    return word;
            }
        },
        changeOption(option) {
            if (option.Key) {
                this.category_filter = option.Key
                this.category_filter_name = option.Name
                actions.setVideowallDimension(this.category_filter)
                actions.setVideowallDimensionName(this.category_filter_name)
                if (this.category_filter === 'Country' || this.category_filter === 'Continent' || this.category_filter === 'Province') {
                    actions.setVideowallPlace(null)
                }
            }
            else {
                this.category_filter = 'Comerciales'
                this.category_filter_name = 'Comerciales'
                actions.setVideowallDimension(this.category_filter)
                actions.setVideowallDimensionName(this.category_filter_name)
            }
            this.opendimensions = !this.opendimensions
        }
    },
    watch: {
        category_filter(n,o) {
            // if(state.videowallTypePlace == 'Provincetops'){
            //     this.changeOption({Key: "Province", Name: "Provincias", Top: true})
            // }
        }
    },
    mounted() {
        this.category_filter = state.videowallDimension
        this.category_filter_name = state.videowallDimensionName
    }
}
</script>