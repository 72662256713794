<template>
    
    <div class="h-auto w-full flex flex-row mt-2 bg-videowall_box rounded-xl overflow-hidden">
                                                
        <div class="h-auto w-full">

            <div class="h-6 flex flex-row jusity-start items-center px-4">

                <span class="text-white font-semibold text-md">{{data.name}}</span>

            </div>

            <div v-if="companySelected" class="h-8 flex flex-row justify-center items-center">

                <div class="h-full w-1/4 flex flex-col justify-center items-center">

                    <span class="text-white text-lg font-semibold -mt-1">{{data.value | reduceBigNumbers()}} <span v-html="unit"></span></span>

                </div>

                <div class="h-full w-1/4 flex flex-col justify-center items-center">
                
                    <span class="text-lg font-semibold -mt-1" :class="{'text-bad': data.growth < 0, 'text-good': data.growth >= 0}"><span v-if="data.growth > 0">+</span>{{data.growth | reduceBigNumbers()}}%</span>
                
                </div>

                <div class="h-full w-1/4 flex flex-col justify-center items-center">
                
                    <span class="text-white text-lg font-semibold -mt-1">{{data.weighing | reduceBigNumbers()}}%</span>
                
                </div>

                <div class="h-full w-1/4 flex flex-col justify-center items-center">
                
                    <span class="text-white text-lg font-semibold -mt-1">{{data.groupValue | reduceBigNumbers()}}%</span>
                
                </div>                                                         

            </div>
            
            <div v-else class="h-8 flex flex-row justify-center items-center">

                <div class="h-full w-1/3 flex flex-col justify-center items-center">

                    <span class="text-white text-lg font-semibold -mt-1">{{data.value | reduceBigNumbers()}} <span v-html="unit"></span></span>

                </div>

                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                
                    <span class="text-lg font-semibold -mt-1" :class="{'text-bad': data.growth < 0, 'text-good': data.growth >= 0}"><span v-if="data.growth > 0">+</span>{{data.growth | reduceBigNumbers()}}%</span>
                
                </div>

                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                
                    <span class="text-white text-lg font-semibold -mt-1">{{data.weighing | reduceBigNumbers()}}%</span>
                
                </div>                                                        

            </div>

        </div>
            

    </div>

</template>

<script>
import { state,actions } from '@/store';

export default {
    props:['data'],
    data(){
        return{

        }
    },
    computed:{
        topindicatorname(){
            return state.videowallIndicatorName
        },
        unit(){
            let unit = '€'
            if(state.videowallIndicator == 1){
                unit = 'm<sup>2</sup>'
            }
            if(state.videowallIndicator == 3){
                unit = '%'
            }
            if(state.videowallIndicator == 2){
                unit = '€/m<sup>2</sup>'
            }
            return unit
        },
        companySelected(){
            return state.videowallCompany
        }
    },
    methods:{

    }
}
</script>