<template>

    <div class="h-auto">

        <div v-if="data[0]" class="h-auto w-full flex flex-row mt-4 bg-videowall_box rounded-xl overflow-hidden cursor-pointer" @click="openCompany(data[0])">

            <div class="h-20 my-auto flex flex-row justify-center items-center w-6">

                <div class="h-16 w-1 rounded-full shadow_box" :class="{'bg-good':data[0].MonthVariation >= 0, 'bg-bad':data[0].MonthVariation < 0 }"></div>

            </div>

            <div class="h-auto w-full">

                <div class="h-10 flex flex-row jusity-start items-center">

                    <span class="text-white font-semibold text-md" :class="{'text-good':data[0].MonthVariation >= 0, 'text-bad':data[0].MonthVariation < 0 }">{{data[0].Name}}</span>

                </div>

                <div class="h-14 flex flex-row justify-center items-center">

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{indicator}}</span>
                        <span class="text-white text-lg font-semibold -mt-1">{{data[0].Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>

                    </div>

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{$t('accumulated')}}</span>
                        <span class="text-white text-lg font-semibold -mt-1">{{data[0].Month | reduceBigNumbers()}} <span v-html="unit"></span></span>

                    </div>

                    <div v-if="indicator == 'Facturación' || indicator == 'Metros cuadrados'" class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{$t('deliveryNotes')}}</span>
                        <span class="text-white text-lg font-semibold -mt-1">{{data[0].DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>

                    </div>

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{$t('variation')}}</span>
                        <span class="text-lg font-semibold -mt-1" :class="{'text-bad': data[0].MonthVariation < 0, 'text-good': data[0].MonthVariation >= 0}">{{data[0].MonthVariation | reduceBigNumbers()}}%</span>

                    </div>

                </div>

            </div>

        </div>

        <div v-if="data[1]" class="h-auto w-full flex flex-row mt-4 bg-videowall_box rounded-xl overflow-hidden cursor-pointer" @click="openCompany(data[1])">

            <div class="h-20 my-auto flex flex-row justify-center items-center w-6">

                <div class="h-16 w-1 rounded-full shadow_box" :class="{'bg-good':data[1].MonthVariation >= 1, 'bg-bad':data[1].MonthVariation < 1 }"></div>

            </div>

            <div class="h-auto w-full">

                <div class="h-10 flex flex-row jusity-start items-center">

                    <span class="text-white font-semibold text-md" :class="{'text-good':data[1].MonthVariation >= 1, 'text-bad':data[1].MonthVariation < 1 }">{{data[1].Name}}</span>

                </div>

                <div class="h-12 flex flex-row justify-center items-center">

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{indicator}}</span>
                        <span class="text-white text-lg font-semibold -mt-1">{{data[1].Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>

                    </div>

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{$t('accumulated')}}</span>
                        <span class="text-white text-lg font-semibold -mt-1">{{data[1].Month | reduceBigNumbers()}} <span v-html="unit"></span></span>

                    </div>

                    <div v-if="indicator == 'Facturación' || indicator == 'Metros cuadrados'" class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{$t('deliveryNotes')}}</span>
                        <span class="text-white text-lg font-semibold -mt-1">{{data[1].DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>

                    </div>

                    <div class="h-full w-1/4 flex flex-col justify-center items-center">

                        <span class="text-videowall_gray text-sm">{{$t('variation')}}</span>
                        <span class="text-lg font-semibold -mt-1" :class="{'text-bad': data[1].MonthVariation < 1, 'text-good': data[1].MonthVariation >= 1}">{{data[1].MonthVariation | reduceBigNumbers()}}%</span>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import { state,actions } from '@/store';

export default {
    props:['data'],
    components:{

    },
    data(){
        return{

        }
    },
    methods:{
        openCompany(company){

            if(company){

                if(company.Guid){

                    if(state.user.rol.Name == 'Propiedad' || state.idCompany == company.Guid){

                        if(company == null){
                            actions.setVideowallCompany(null)
                        } else {
                            actions.setVideowallCompany(company)
                        }

                        this.$emit('openCompany',company)

                    }

                    if(company.Guid == '1BF7128E394211EA966B005056AEAA71'){

                        if(company.IndustrialGroup == true){
                            actions.setVideowallIndustrialGroup(false)
                        } else{
                            actions.setVideowallIndustrialGroup(true)
                        }

                    } else {

                        actions.setVideowallIndustrialGroup(null)

                    }



                } else {

                    for (let index = 0; index < Object.values(this.companis).length; index++) {

                        if(Object.values(this.companis)[index][0].Guid == company.toUpperCase()){

                            actions.setVideowallCompany(Object.values(this.companis)[index][0])

                            if(Object.values(this.companis)[index][0].Guid == '1BF7128E394211EA966B005056AEAA71'){

                                if(Object.values(this.companis)[index][0].IndustrialGroup == true){
                                    actions.setVideowallIndustrialGroup(false)
                                } else{
                                    actions.setVideowallIndustrialGroup(true)
                                }

                            } else {

                                actions.setVideowallIndustrialGroup(null)

                            }

                            this.$emit('openCompany',Object.values(this.companis)[index][0])

                        }

                    }

                }

            } else {

                actions.setVideowallCompany(null),
                this.$emit('openCompany',company)

            }

        }
    },
    computed:{
        indicator(){
            return state.videowallIndicatorName
        },
        unit(){
            let unit = '€'
            if(state.videowallIndicator == 1){
                unit = 'm<sup>2</sup>'
            }
            if(state.videowallIndicator == 3){
                unit = '%'
            }
            return unit
        }
    }
}
</script>
