<template>
    <div class="chart-wrap w-full h-full" ref="container-chart">
        <highmaps class="chart-container w-full h-full" :options="options" ref="map"></highmaps>
    </div>
</template>

<script>
import worldMapData from '../utils/map.js';
import { state,actions } from '@/store';
import {reduceBigNumbers} from '../main.js'

    export default {
        props: ['data','refMap','type'],
        data() {
            return {
                options: {
                    chart: {
                        borderWidth: 0,
                        map: worldMapData,
                        backgroundColor: 'rgba(0,0,0,0)',
                        margin:[0,0,0,0]
                    },

                    credits:{
                        enabled: false
                    },

                    title: {
                        text: ''
                    },

                    subtitle: {
                        text: ''
                    },

                    legend: {
                        enabled: false
                    },

                    plotOptions:{
                        
                    },

                    tooltip:{
                        enabled: true,
                        formatter: function() {
                            return '<span class="font-semibold">' + this.point.name + '</span><br><span class="font-semibold" :style="color:#7070F0">'+ reduceBigNumbers(this.point.value) + '%</span>';
                        }
                    },

                    mapNavigation: {
                        enabled: false,
                    },

                    colorAxis:{
                        stops:[
                            [0,'#56526f'],
                            [0.01,'#56526F'],
                            [0.05,'#6661ae'],
                            [0.15,'#6a66c3'],
                            [0.5,'#6e6bd9'],
                            [1,'#7070f0']
                        ]
                    },

                    series: [
                        {
                            name: '',
                            data: [],
                            joinBy: ['hc-key', 'code'],
                            nullColor: '#484848',
                            borderColor: '#2A2A2A'
                        }
                    ]
                }
            }
        },
        methods: {
            getData(){

                let chart = this.$refs['map'].chart;
                let countries = [];

                if(this.data){

                    for (let index = 0; index < this.data.length; index++) {

                        if(this.data[index].iso){                    

                            countries.push(
                                {
                                    name: this.data[index].name,
                                    code: this.data[index].iso.toLowerCase(),
                                    value: this.data[index].weighing
                                }
                            )

                        }

                    }


                    chart.series[0].setData(countries)

                }
                
            }
        },
        watch: {
            data(){
                this.getData();
            }
        },
        mounted(){
            this.getData();
        }
    };
</script>
