<template>
    
    <div class="h-full w-full z-10">

        <All v-if="typePlace" :model="typePlace" :immediate="false" v-slot="{data, loading}" ref="refplacess">

            <div v-if="!loading" class="h-auto z-10 padre">

                <el-select v-model="value" id="selectplace" filterable size="mini" :placeholder="$t('select')" class="w-full">
                    <el-option
                    v-for="item in data"
                    :key="item.Id"
                    :label="item.Name || item.idLink"
                    :value="item.Id">
                    </el-option>
                    <el-option
                    :key="'all'"
                    :label="'TODOS'"
                    :value="null"
                    class="w-full">
                    </el-option>
                </el-select>

            </div>

        </All>

    </div>

</template>

<script>
import { All } from '@/api/components';
import { state,actions } from '@/store';

export default {
    components:{
        All
    },
    data(){
        return{
            value:null,
            open:false,
            text:''
        }
    },
    methods:{
      
    },
    computed:{
        typePlace(){
             
            switch (state.videowallTypePlace) {
                case 'Country':
                    return 'Countrytops'
                    break;
                case 'Countrytops':
                    return 'Countrytops'
                    break;
                case 'Continent':
                    return 'Continenttops'
                    break;
                case 'Continenttops':
                    return 'Continenttops'
                    break;
                case 'Province':
                    return 'Provincetops'
                    break;
                case 'Provincetops':
                    return 'Provincetops'
                    break;
            }

        },
        dimension(){
            return state.videowallDimension
        }

    },
    watch:{
        value(n,o){
            actions.setVideowallPlace(n)
        },
        typePlace(){

            this.value = null

            setTimeout(() => {
                this.$refs['refplacess'].request();
            }, 100);

        },
        dimension(){

            setTimeout(() => {
                this.$refs['refplacess'].request();
            }, 100);

        }
    },
    mounted(){
        this.value = state.videowallPlace
        this.$refs['refplacess'].request();
    }
}
</script>

<style lang="css" >

    .padre .el-input__inner{

        background-color:#202837 !important;
        border: 0 !important;
        color: white !important;
        border-bottom: 2px solid #7070F0 !important;
        border-radius: 0 !important;

    }

    .padre .el-select-dropdown{

        background-color: #202837 !important;
        color: white !important;

    }

</style>