<template>
    <div class="chart-wrap w-full h-full" ref="container-chart">
        <highmaps class="chart-container w-full h-full" :options="options" ref="mapSpain"></highmaps>
    </div>
</template>

<script>
import mapdata from '../../utils/mapspain.js';
import {reduceBigNumbers} from '../../main.js';

    export default {
        props: ['data','refMap'],
        data() {
            return {
                countries: [],
                options: {
                    chart: {
                        borderWidth: 0,
                        map: mapdata,
                        backgroundColor: 'rgba(0,0,0,0)',
                        margin:[0,0,0,0]
                    },

                    credits:{
                        enabled: false
                    },

                    title: {
                        text: ''
                    },

                    subtitle: {
                        text: ''
                    },

                    legend: {
                        enabled: false
                    },

                    plotOptions:{
                        
                    },

                    tooltip:{
                        enabled: true,
                        formatter: function() {
                            return '<span class="font-semibold">' + this.point.name + '</span><br><span class="font-semibold" :style="color:#7070F0">'+ reduceBigNumbers(this.point.value) + '%</span>';
                        }
                    },

                    mapNavigation: {
                        enabled: false,
                    },

                    colorAxis:{
                        stops:[
                            [0,'#56526f'],
                            [0.01,'#56526F'],
                            [0.05,'#6661ae'],
                            [0.15,'#6a66c3'],
                            [0.5,'#6e6bd9'],
                            [1,'#7070f0']
                        ]
                    },

                    series: [
                        {
                            name: 'Data',
                        // data:[
                            //         ['es-pm', 0],
                            //         ['es-va', 1],
                            //         ['es-le', 2],
                            //         ['es-me', 3],
                            //         ['es-p', 4],
                            //         ['es-s', 5],
                            //         ['es-na', 6],
                            //         ['es-ce', 7],
                            //         ['es-cu', 8],
                            //         ['es-vi', 9],
                            //         ['es-ss', 10],
                            //         ['es-gr', 11],
                            //         ['es-mu', 12],
                            //         ['es-bu', 13],
                            //         ['es-sa', 14],
                            //         ['es-za', 15],
                            //         ['es-hu', 16],
                            //         ['es-m', 17],
                            //         ['es-gu', 18],
                            //         ['es-sg', 19],
                            //         ['es-se', 20],
                            //         ['es-t', 21],
                            //         ['es-te', 22],
                            //         ['es-v', 23],
                            //         ['es-bi', 24],
                            //         ['es-or', 25],
                            //         ['es-l', 26],
                            //         ['es-z', 27],
                            //         ['es-gi', 28],
                            //         ['es-ab', 29],
                            //         ['es-a', 30],
                            //         ['es-av', 31],
                            //         ['es-cc', 32],
                            //         ['es-to', 33],
                            //         ['es-ba', 34],
                            //         ['es-co', 35],
                            //         ['es-h', 36],
                            //         ['es-c', 37],
                            //         ['es-ma', 38],
                            //         ['es-po', 39],
                            //         ['es-lo', 40],
                            //         ['es-so', 41],
                            //         ['es-al', 42],
                            //         ['es-b', 43],
                            //         ['es-ca', 44],
                            //         ['es-o', 45],
                            //         ['es-cs', 46],
                            //         ['es-cr', 47],
                            //         ['es-j', 48],
                            //         ['es-lu', 49],
                            //         ['es-tf', 50],
                            //         ['es-gc', 51]
                        // ],
                            // data:[
                            //     {
                            //         name: 'asturias',
                            //         code: 'es-o',
                            //         color: '#ffff'
                            //     }
                            // ],
                            data:[],
                            joinBy: ['hc-key', 'code'],
                            nullColor: '#484848',
                            borderColor: '#2A2A2A'
                        }
                    ]
                }
            }
        },
        methods: {
            getData(){

                let chart = this.$refs['mapSpain'].chart;
                let countries = [];

                for (let index = 0; index < this.data.length; index++) {

                    if(this.data[index].iso){

                        countries.push(
                            {
                                name: this.data[index].name,
                                code: this.data[index].iso.toLowerCase(),
                                value: this.data[index].weighing
                            }
                        )

                    }

                }

                chart.series[0].setData(countries)
                
            },
            colormap(value){

                if(value < 0){

                    if(value > -5){

                        return '#ffb52c'

                    } else {

                        return '#FF6154'

                    }

                } else {

                    return '#7beeb3'

                }

            }        
        },
        watch: {
            data(){
                this.getData();
            }
        },
        mounted(){
            if(this.data){
                this.getData();
            }
                
        }
    };
</script>
