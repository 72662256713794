<template>
    <div class="h-auto flex flex-col justify-between px-4">
        <div class="h-1 rounded-full bg-videowall_bg flex flex-row justify-start items-center relative mb-1 bg-bgchart overflow-hidden">
            <div class="h-full rounded-full transition shadow-lg"
                 :class="(current > prev) ? 'bg-good': 'bg-bad'"
                 :style="{width:  ( current > prev ) ? current+'%' : prev+'%' }"
                 >
            </div>
            <div class="h-full rounded-full bg-videowall_bg absolute top-0 left-0 transition " 
                 v-if="current > 0"
                 :style="{width:  ( current > prev ) ? (prev+.5)+'%' : (current+.5)+'%' }"
                 >
            </div>
            <div class="h-full rounded-full bg-red absolute top-0 left-0 transition" 
                 v-if="current > 0"
                 :style="{width:  ( current > prev ) ? prev+'%' : current+'%' }"
                 >
            </div>
        </div>
        <div class="h-1 rounded-full bg-videowall_bg flex flex-row justify-start items-center overflow-hidden bg-bgchart">
            <div class="h-full rounded-full bg-sky shadow-lg" :style="'width:'+ prev +'%; transition:.5s;'"></div>
        </div>
    </div>
</template>
<script>
    export default {
        props:['current','prev']
    }
</script>