<template>
    <highcharts class="chart h-full w-full chart-wrap" :options="options" ref="chartId" id="chartId" />
</template>

<script>
    export default {
        props: ['data'],
        data: function() {
            return {
                chart: null
            }
        },
        computed: {
            options() {
                return {
                    chart: {
                        type: 'solidgauge',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        margin:[0,0,0,0]
                    },
                    title: { text: '' },
                    tooltip: { enabled: false },
                    credits: { enabled: false },
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ 
                            outerRadius: '99%',
                            innerRadius: '92%',
                            borderWidth: 0,
                            backgroundColor: '#121822'
                        },
                        { 
                            outerRadius: '90%',
                            innerRadius: '83%',
                            borderWidth: 0,
                            backgroundColor: '#121822'
                        }]
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },
                    series: [
                        {
                            name: 'a',
                            data: [ {
                                color: (this.data.actual > this.data.prev) ? '#7beeb3' : '#FF6154',
                                radius: '99%',
                                innerRadius: '92%',
                                y: (this.data.actual > this.data.prev) ? this.data.actual : this.data.prev
                                //y: 0
                            },{
                                color: '#121822',
                                radius: '100%',
                                innerRadius: '91%',
                                y: (this.data.actual > this.data.prev) ?  this.data.prev+.3 : this.data.actual+.3
                                //y: 0
                            },
                           {
                               color: '#7070F0',
                               radius: '99%',
                               innerRadius: '92%',
                               y: (this.data.actual > this.data.prev) ? this.data.prev : this.data.actual
                           } ]
                        },
                        {
                            name: 'b',
                            data: [{
                                color: '#02B1DB',
                                radius: '90%',
                                innerRadius: '83%',
                                y: this.data.prev
                            }]
                        }
                    ],
                    responsive: {}
                }
            }
        },
        mounted() {
            const chartI = this.$refs.chartId;
            const chart = chartI.chart;
            setTimeout(() => {
                chart.reflow();
            }, 200);
        }
    }
</script>

<style>
    .chart-wrap {
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap svg {
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap .highcharts-container {
        height: 100% !important;
        width: 100% !important;
    }
    .chart-wrap > div {
        height: 100% !important;
        width: 100% !important;
    }
</style>