<template>

    <div class="h-full p-4 overflow-auto">

        <div v-for="(el,index) in data" :key="index" class="h-16 w-full rounded-xl bg-videowall_box mb-2 flex flex-row overflow-hidden">

            <div class="h-full w-16 flex flex-col justify-center items-center">

                <div class="h-8 w-8 rounded-full bg-green"></div>
                <div class="h-auto w-full flex flex-row justify-center items-center">
                    <i class="mdi mdi-star text-gold text-3xs"></i>
                    <span class="text-4xs text-gray">{{$t('day')}} NOV'19</span>
                </div>

            </div>

            <div class="h-full flex-1">

                <div class="h-10 w-full flex flex-row">

                    <div class="h-full w-4/12 flex flex-col justify-center items-start">
                        <span class="text-xs text-white font-semibold">Jerry Hayes</span>
                        <span class="text-gray text-4xs">{{$t('article')}}</span>
                    </div>
                    <div class="h-full w-2/12 flex flex-col justify-center items-center">
                        <span class="text-xs text-white font-semibold">22.3K</span>
                        <span class="text-gray text-4xs">{{$t('day')}} NOV'19</span>
                    </div>
                    <div class="h-full w-2/12 flex flex-col justify-center items-center">
                        <span class="text-xs text-white font-semibold">22.3K</span>
                        <span class="text-gray text-4xs">Ac. NOV'19</span>
                    </div>
                    <div class="h-full w-2/12 flex flex-col justify-center items-center">
                        <span class="text-xs text-white font-semibold">22.3K</span>
                        <span class="text-gray text-4xs">Alc. NOV'19</span>
                    </div>
                    <div class="h-full w-2/12 flex flex-col justify-center items-center">
                        <span class="text-xs text-white font-semibold">22.3K</span>
                        <span class="text-gray text-4xs">Real NOV'18</span>
                    </div>

                </div>

                <div class="h-6 w-full flex flex-row">

                    <div class="h-full w-10/12">
                        <doubleChartVideowall :current="65" :prev="80"></doubleChartVideowall>
                    </div>

                    <div class="h-full w-2/12 flex flex-col justify-center items-center">
                        <span class="text-2xs text-white">35%</span>
                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import doubleChartVideowall from '@/components/doubleChartVideowall.vue';

export default {
    props:['data'],
    components:{
        doubleChartVideowall
    }
}
</script>
