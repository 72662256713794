<template>
    
    <div class="h-full w-full absolute top-0 left-0 z-20 flex flex-row justify-center items-center" style="backdrop-filter:blur(5px)">

        <All model="Zones" :immediate="false" :query="query" v-slot="{data,loading}" ref="zones">
        
            <div v-if="!loading" class="h-160 w-4/5 rounded-xl bg-videowall_module shadow_box p-4 flex flex-col">

                <div class="h-auto flex flex-row justify-start items-center">

                    <div class="h-6 w-6 rounded-full bg-videowall_box flex flex-row justify-center items-center mr-2 cursor-pointer" @click="groupmodal()">
                        <i class="mdi mdi-chevron-left text-white text-md"></i>
                    </div>

                    <span class="text-lg text-white font-semibold">{{$t('commercial')}} {{comercialtype}}</span>

                    <i class="mdi mdi-arrow-collapse text-videowall_gray ml-auto text-md cursor-pointer" @click="groupmodal()"></i>

                </div>

                <div class="flex-1 flex flex-row justify-start items-center videowall_scrollbar flex-wrap overflow-auto">

                   <zone v-for="(el,key) in data" 
                        :data="el" 
                        :key="key" 
                        :rol="rol" 
                        :dimensionName="topindicatorname">
                    </zone>

                </div>

            </div>

            <div v-else class="h-160 w-4/5 relative">

                <loader :loading="loading"></loader>

            </div>

        </All>

    </div>

</template>

<script>
import { All,Request } from '@/api/components';
import { state,actions } from '@/store';
import bubblesGroupFacturation from './bubblesGroupFacturation.vue';
import bubblesGroupMetros from './bubblesGroupMetros.vue';
import bubblesGroupPreciomedio from './bubblesGroupPreciomedio.vue';
import bubblesGroupMargen from './bubblesGroupMargen.vue';
import loader from './loaderVideowall.vue';
import zone from './zone.vue';

export default {
    props:['saletype'],
    components:{
        bubblesGroupFacturation,
        All,
        Request,
        bubblesGroupMetros,
        bubblesGroupPreciomedio,
        bubblesGroupMargen,
        loader,
        zone
    },
    data(){
        return{
            yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY")
        }
    },
    methods:{
        groupmodal(){
            this.$emit('comercialsmodal')
        }
    },
    computed:{
        period(){
            return state.videowallPeriod
        },
        query(){
            return{
                period: this.period,
                Dimension: state.videowallIndicator,
                Company: state.videowallCompany.Guid,
                SaleType: this.saletype,
                Place: this.videowallplace,
                PlaceId: state.videowallPlace,
                IndustrialGroup: state.videowallIndustrialGroup
            }
        },
        comercialtype(){
            
            if(this.saletype == '24C85D60394211EA966B005056AEAA71'){
                return 'EXPORTACIÓN'
            } else {
                return 'NACIONALES'
            }

        },
        rol(){
            return state.user.rol.Name
        },
        topindicatorname(){
            return state.videowallIndicatorName
        },
        videowallplace(){
            switch (state.videowallTypePlace) {
                case 'Continenttops':
                    return 'Continent'
                    break;
                case 'Countrytops':
                    return 'Country'
                    break;
                case 'Provincetops':
                    return 'Province'
                    break;
            }
        }
    },
    mounted(){
        
        setTimeout(() => {
           this.$refs['zones'].request(); 
        }, 100);

    }
}
</script>