<template>

    <div class="rounded-xl" :class="{'h-148.8': optionselected === 3 || modal, 'h-16': optionselected !== 3,
        'mt-4':modal, 'mt-1': !modal}" style="transition: all .3s" @click="changeOption(3)">
        <div v-if="modal" class="h-auto flex flex-col rounded-xl bg-videowall_box py-1">
            <div class="h-8 w-full flex flex-row justify-center items-center px-2">
                <span class="text-white text-md font-semibold">{{ $t('margin') }} <small class="text-videowall_gray">
                    ({{ date |  moment('DD MMM YY') }})</small>
                </span>
            </div>
            <div class="h-12 flex flex-row">
                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                    <span class="text-sm text-videowall_gray">{{ $t('day') }}</span>
                    <span class="text-md text-white font-semibold">{{ data.total.BubbleMargin.Yesterday | reduceBigNumbers() }} %</span>
                </div>
                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                    <span class="text-sm text-videowall_gray">{{ $t('accumulated') }}</span>
                    <span class="text-md text-white font-semibold">{{ data.total.BubbleMargin.Month | reduceBigNumbers() }} %</span>
                </div>
                <div class="h-full w-1/3 flex flex-col justify-center items-center">
                    <span class="text-sm text-videowall_gray">{{ $t('variation') }}</span>
                    <span class="text-md font-semibold" :class="{'text-bad': data.total.BubbleMargin.MonthVariation < 0,
                        'text-good': data.total.BubbleMargin.MonthVariation >= 0}">{{ data.total.BubbleMargin.MonthVariation | reduceBigNumbers() }}
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="h-16 flex flex-row justify-start items-center  bg-videowall_box rounded-xl">
            <div class="h-full w-1/4 flex flex-col justify-center items-start px-2">
                <span class="text-white text-md font-semibold">{{ $t('margin') }}  <br><small class="text-videowall_gray">({{ date |  moment('DD MMM YY') }})</small></span>
            </div>
            <div class="h-full w-1/4 flex flex-col justify-center items-center">
                <span class="text-sm text-videowall_gray">{{ $t('day') }}</span>
                <span class="text-md text-white font-semibold">{{ data.total.BubbleMargin.Yesterday | reduceBigNumbers() }} %</span>
            </div>
            <div class="h-full w-1/4 flex flex-col justify-center items-center">
                <span class="text-sm text-videowall_gray">{{ $t('accumulated') }}</span>
                <span class="text-md text-white font-semibold">{{ data.total.BubbleMargin.Month | reduceBigNumbers() }} %</span>
            </div>
            <div class="h-full w-1/4 flex flex-col justify-center items-center">
                <span class="text-sm text-videowall_gray">{{ $t('variation') }}</span>
                <span class="text-md font-semibold" :class="{'text-bad': data.total.BubbleMargin.MonthVariation < 0,
                    'text-good': data.total.BubbleMargin.MonthVariation >= 0}">{{ data.total.BubbleMargin.MonthVariation | reduceBigNumbers() }}
                </span>
            </div>
        </div>
        <div v-if="optionselected === 3 || modal" class="h-130 overflow-hidden bg-videowall_module" :class="{'pb-2':modal}">
            <div class="h-1/2 w-full flex flex-row overflow-hidden py-2">
                <div class="w-full h-full flex flex-col rounded-xl bg-videowall_box px-6 pb-2">
                    <div class="flex-1 flex flex-col justify-center items-center">
                        <div class="h-44 w-44 rounded-full flex flex-col overflow-hidden" :class="getClassColor('total')">
                            <div class="flex-1 flex flex-col justify-center items-center">
                                <span class="text-md text-white">{{ $t('margin') }}</span>
                                <span class="text-lg text-white font-semibold">{{ total.Month | reduceBigNumbers(2) }} %</span>
                                <span class="text-md text-white">{{ $t('value') }} {{ prevperiod }}</span>
                                <span class="text-lg text-white font-semibold">{{ total.PastYear | reduceBigNumbers() }} %</span>
                            </div>
                        </div>
                    </div>
                    <div class="h-10 flex flex-row justify-between items-center px-4">
                        <div class="h-full w-full flex flex-col justify-center items-center">
                            <span class="text-sm font-semibold text-videowall_gray">Var. {{periodo}}</span>
                            <span class="text-md font-semibold text-white"><span v-if="total.MonthVariation >= 0">+</span>{{ total.MonthVariation | reduceBigNumbers() }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-1/2 w-full flex flex-row overflow-hidden">
                <div class="h-full w-1/2 flex flex-col mr-1">
                    <div class="w-full h-full flex flex-col rounded-xl bg-videowall_box pb-2">
                        <div class="h-auto flex flex-row justify-center items-center">
                            <span class="text-sm text-videowall_gray font-semibold">{{ $t('national') }}</span>
                        </div>
                        <div class="flex-1 flex flex-col justify-center items-center">
                            <div class="h-40 w-40 rounded-full flex flex-col justify-center items-center" :class="getClassColor('nacional')">
                                <span class="text-sm text-white">{{ $t('margin') }}</span>
                                <span class="text-lg text-white font-semibold">{{ nacional.Month | reduceBigNumbers(2) }} %</span>
                                <span class="text-sm text-white">{{ $t('value') }} {{ prevperiod }}</span>
                                <span class="text-lg text-white font-semibold">{{ nacional.PastYear | reduceBigNumbers() }} %</span>
                            </div>
                        </div>
                        <div class="h-10 flex flex-row justify-between items-center px-4">
                            <div class="h-full w-full flex flex-col justify-center items-center">
                                <span class="text-sm font-semibold text-videowall_gray">Var. {{ periodo }}</span>
                                <span class="text-md font-semibold text-white"><span v-if="nacional.MonthVariation >= 0">+</span>{{ nacional.MonthVariation | reduceBigNumbers() }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-full w-1/2 flex flex-col ml-1">
                    <div class="w-full h-full flex flex-col rounded-xl bg-videowall_box pb-2">
                        <div class="h-auto flex flex-row justify-center items-center">
                            <span class="text-sm text-videowall_gray font-semibold">{{ $t('exportCapitalLetter') }}</span>
                        </div>
                        <div class="flex-1 flex flex-col justify-center items-center">
                            <div class="h-40 w-40 rounded-full flex flex-col justify-center items-center" :class="getClassColor('exportacion')">
                                <span class="text-sm text-white">{{$t('margin')}}</span>
                                <span class="text-lg text-white font-semibold">{{exportacion.Month | reduceBigNumbers(2)}} %</span>
                                <span class="text-sm text-white">{{$t('value')}} {{ prevperiod }}</span>
                                <span class="text-lg text-white font-semibold">{{exportacion.PastYear | reduceBigNumbers()}} %</span>
                            </div>
                        </div>
                        <div class="h-10 flex flex-row justify-between items-center px-4">
                            <div class="h-full w-full flex flex-col justify-center items-center">
                                <span class="text-sm font-semibold text-videowall_gray">Var. {{periodo}}</span>
                                <span class="text-md font-semibold text-white"><span v-if="exportacion.MonthVariation >= 0">+</span>{{ exportacion.MonthVariation | reduceBigNumbers() }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="modal" class="h-32 py-2"><basic-line-chart chartId="graph" :data="total.Historical" /></div>

    </div>

</template>

<script>
import { state,actions } from '@/store';
import basicLineChart from '../basiclinechart.vue';

export default {
    props: ['data', 'module', 'modal', 'date'],
    components: {
        basicLineChart
    },
    data() {
        return {
            yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
        }
    },
    methods: {
        getClassColor(option) {
            if (this[option].Month <= this[option].PastYear && this[option].Month  >= this[option].PastYear - this[option].PastYear*.1) { return 'bg-med' }
            if (this[option].Month <= this[option].PastYear - this[option].PastYear*.1) { return 'bg-bad' }
            return 'bg-green'
        },
        changeOption(option) {
            if (state.videowallIndicator !== option) {
                actions.setVideowallIndicator(option)
                actions.setVideowallIndicatorName('Margen')
            }
        }
    },
    computed:{
        nacional() { return this.data['24C8C714394211EA966B005056AEAA71'].BubbleMargin },
        exportacion() { return this.data['24C85D60394211EA966B005056AEAA71'].BubbleMargin },
        total() { return this.data.total.BubbleMargin },
        prevperiod() { return this.$moment().subtract(1, 'years').format("YYYY") },
        periodo() {
            switch (state.videowallPeriod) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },
        optionselected() { return state.videowallIndicator }
    }
}
</script>
