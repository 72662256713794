<template>
    
    <div class="h-6 flex flex-row justify-between items-center z-20">
            
        <div class="h-full w-full relative">
            
            <div class="absolute top-0 left-0 w-full z-20 bg-videowall_gray cursor-pointer overflow-hidden flex flex-col shadow_box" :class="{'h-6 rounded-xl': !opendimensions, 'h-auto rounded-xl': opendimensions}" style="transition: all .3s;">

                <div class="h-6 w-full flex flex-row justify-between items-center px-2 border-b border-videowall_box rounded-xl shadow bg-red relative" @click="opendimensions = !opendimensions">

                    <span class="text-sm text-white font-semibold">{{namefilter || 'Todo el mundo'}}</span>
                    <i class="mdi mdi-menu-down text-white absolute top-0 right-0 mr-2"></i>


                </div>

                <div v-if="opendimensions" class="flex-1 overflow-auto videowall_scrollbar">

                    <div v-for="(el,index) in places" :key="index" class="h-6 w-full mt-1 flex flex-row justify-center items-center hover:bg-red_transparent" @click="changeOption(el)">
                        <span class="text-sm text-white" :class="{'font-bold': namefilter == el.name}">{{el.name}}</span>
                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import { All,Request } from '@/api/components';
import { state,actions } from '@/store';

export default {
    components:{
        All
    },
    data(){
        return{
            opendimensions:false,
            category_filter:null,
            category_filter_name:null,
            places: [
                { 
                    name: this.$t("continent"),
                    value: 'Continenttops'
                },
                { 
                    name: this.$t("country"),
                    value: 'Countrytops'
                },
                { 
                    name: this.$t("province"),
                    value: 'Provincetops'
                }
            ]
        }
    },
    methods:{

        changeOption(option){

            actions.setVideowallTypePlace(option.value)
            actions.setVideowallTypePlaceName(option.name)

            this.opendimensions = !this.opendimensions

        },
        test(option){

            if(state.videowallDimension == 'Country'){

                switch (option) {
                    case 'Pais':
                        return true
                        break;
                    case 'Continente':
                        return false
                        break;
                    case 'Provincias':
                        return true
                        break;
                }

            } else if(state.videowallDimension == 'Province'){

                switch (option) {
                    case 'Pais':
                        return false
                        break;
                    case 'Continente':
                        return false
                        break;
                    case 'Provincias':
                        return true
                        break;
                }

            }

        }

    },
    computed:{
        namefilter(){
            return state.videowallTypePlaceName
        }
    },
    watch:{

        namefilter(n,o){

            this.category_filter = state.videowallTypePlace
            this.category_filter_name = state.videowallTypePlaceName
        
        }

    },
    mounted(){
        this.category_filter = state.videowallTypePlace
        this.category_filter_name = state.videowallTypePlaceName
    }
}
</script>