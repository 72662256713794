<template>
    
    <div class="h-full flex flex-col overflow-hidden">

        <div class="h-2 mt-4 px-4 flex flex-row">
            <div class="h-full w-5/12 flex flex-row justify-start items-center px-2">
                
            </div>
            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                <span class="text-4xs text-white">{{indicator}}</span>
            </div>
            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                <span class="text-4xs text-white">{{$t('growth')}}</span>
            </div>
            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                <span class="text-4xs text-white">{{$t('weight')}}</span>
            </div>
        </div>

        <div class="flex-1 overflow-auto">

            <div v-for="(el,index) in data" :key="index" class="h-6 bg-videowall_box rounded-xl flex flex-row mx-4" :class="{'mt-1': index != 0}">

                <div class="h-full w-5/12 flex flex-row justify-start items-center px-2">
                    <!-- <img src="/img/flags/ES.svg" class="h-3 mr-2"> -->
                    <span class="text-2xs text-white font-semibold truncate">{{el.name}}</span>
                </div>
                <div class="h-full w-3/12 flex flex-row justify-center items-center">
                    <span class="text-xs text-white font-semibold">{{el.value | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-full w-3/12 flex flex-row justify-center items-center">
                    <span class="text-xs font-semibold" :class="{'text-good': el.growth > 0, 'text-bad': el.growth < 0}"><span v-if="el.growth > 0">+</span>{{el.growth | reduceBigNumbers()}}%</span>
                </div>
                <div class="h-full w-3/12 flex flex-row justify-center items-center">
                    <span class="text-xs font-semibold" :class="{'text-good': el.weighing > 0, 'text-bad': el.weighing < 0}"><span v-if="el.weighing > 0">+</span>{{el.weighing | reduceBigNumbers()}}%</span>
                </div>
        
            </div>

        </div>

        
        
    </div>

</template>

<script>
import { state,actions } from '@/store';

export default {
    props:['data'],
    computed:{
        indicator(){
            return state.videowallIndicatorName
        },
        unit(){
            let unit = '€'
            if(state.videowallIndicator == 1){
                unit = 'm<sup>2</sup>'
            }
            if(state.videowallIndicator == 3){
                unit = '%'
            }
            return unit
        }
    }
}
</script>