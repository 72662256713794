<template>

    <div class="h-16 flex flex-row justify-start items-center  bg-videowall_box rounded-xl mt-1">

        <All model="Groupotherbubbles" :immediate="true" :query="query" :data.sync="otherbubbles" v-slot="{data:otherbubbles, loading:loadingbubbles23}"></All>

        <div v-if="otherbubbles" class="h-full w-full flex flex-row justify-start items-center">

            <div class="h-full w-1/5 flex flex-col justify-center items-start pl-2">

                <span class="text-white text-md font-semibold text-left">{{$t('deliveryNotes')}} <small class="text-videowall_gray">(hoy)</small></span>
                <span class="text-white text-xs font-semibold text-left">Ult. Sinc: {{otherbubbles.BubbleDeliveryNoteOrders.SyncTime | moment('HH:mm')}}h</span>

            </div>

            <div v-if="otherbubbles" class="h-full w-2/5 flex flex-col justify-center items-center">

                <span class="text-sm text-videowall_gray">Actual €</span>
                <span class="text-md text-white font-semibold">{{otherbubbles.BubbleDeliveryNoteOrders.Day | reduceBigNumbers(2)}} €</span>
            
            </div>

            <div v-if="otherbubbles" class="h-full w-2/5 flex flex-col justify-center items-center">
            

                <span class="text-sm text-videowall_gray">Actual m<sup>2</sup></span>
                <span class="text-md text-white font-semibold">{{otherbubbles.BubbleDeliveryNoteOrders.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
            
            </div>

        </div>

    </div>

</template>

<script>
import { All,Request } from '@/api/components';
import { state,actions } from '@/store';

export default {
    props:['date'],
    components:{
        All,
        Request
    },
    data(){
        return{
            otherbubbles:null
        }
    },
    computed:{
        query(){
            return{
                period: state.videowallPeriod
            }
        }
    }
}
</script>
