<template>
    <div class="w-48 h-full">

        <div class="bg-videowall_bg rounded-lg p-1 flex w-full h-full">

            <input type="radio" id="Peso" value='Peso' v-model="mode" class="swicth">

            <label class="w-24 h-full flex justify-center items-center flex text-disabled text-sm" for="Peso">
                <span :class="{'w-full h-full rounded-lg bg-videowall_module shadow_box text-white transition flex justify-center items-center':mode=='Peso'}">{{$t('weight')}}</span> 
            </label>

            <input type="radio" id="Crecimiento" value='Crecimiento' v-model="mode" class="swicth">

            <label class="w-24 h-full flex justify-center items-center flex text-disabled text-sm" for="Crecimiento">
                <span :class="{'w-full h-full rounded-lg bg-videowall_module shadow_box text-white transition flex justify-center items-center':mode=='Crecimiento'}">{{$t('growth')}}</span> 
            </label>

        </div>   

    </div>
</template>

<script>
import { state,actions } from '@/store';

export default {
    data(){
        return{
           mode: null
        }
    },
    watch:{
        mode(n,o){
            
            if(n != o){

                if(n == 'Peso'){

                    actions.setVideowallGrowth(true)

                } else {

                    actions.setVideowallGrowth(false)

                }

            }

        }
    },
    mounted(){

        if(state.videowallGrowth){

            this.mode = 'Peso'

        } else {

            this.mode = 'Crecimiento'

        }

    }
}
</script>
<style>
.swicth{
    display: none;
}


</style>