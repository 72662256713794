<template>

    <div class="h-auto videowall">
        <div v-if="rol === 'Propiedad' || rol === 'Gerente'" class="h-screen w-screen bg-videowall_bg
            relative flex flex-row relative">
            <div class="h-8 w-full top-0 left-0 bg-videowall_module absolute flex flex-row 
                justify-start items-center z-50">
                <div class="h-full w-4/12">
                    <div class="h-full w-full flex flex-row justify-start items-center px-4" @click="filtersmenu = !filtersmenu">
                        <span class="text-md mr-1 text-white">{{ $t('period') }}:</span>
                        <span class="text-md mr-4 font-semibold text-purple">{{ periodo }} <i class="mdi mdi-chevron-down"></i></span>
                        <span class="text-md mr-1 text-white">{{ $t('indicator') }}:</span>
                        <span class="text-md font-semibold text-purple">{{ translate(topindicatorname) }}</span>
                        <i class="mdi mdi-chevron-down text-purple"></i> 
                        <!-- <span class="text-white ml-2">expandcompanymenu - {{expandcompanymenu}}</span> -->
                    </div>
                </div>

                <div class="h-full w-6/12">
                    <div class="h-full w-full">
                        <!-- <div class="h-full w-full flex flex-row justify-start items-center px-4">
                            <span class="text-md mr-1 text-white">{{$t('period')}}:</span>
                            <span class="text-md mr-auto font-semibold text-purple">{{periodo}} <i class="mdi mdi-chevron-down"></i> </span>
                            <span class="text-md mr-1 text-white">{{$t('place')}}:</span>
                            <span class="text-md font-semibold text-purple">{{typeplacename || 'Todo el mundo'}}</span>
                            <span v-if="placeName" class="text-md font-semibold text-purple">, {{placeName}}</span>
                            <i class="mdi mdi-chevron-down mr-auto text-purple"></i>
                            <span class="text-md mr-1 text-white">{{$t('indicator')}}:</span>
                            <span class="text-md font-semibold text-purple">{{topindicatorname}}</span>
                            <i class="mdi mdi-chevron-down text-purple"></i>
                        </div> -->
                    </div>
                </div>

                <div class="h-full w-4/12 flex flex-row justify-end items-center px-4">
                    <span class="text-white text-md mr-2">{{ newdate | moment("DD MMMM YYYY") }}</span>
                    <span class="text-white text-md mr-8">{{ newdate | moment("HH:mm:ss") }}</span>
                    <img v-if="user.relation.length>0" :src="'/img/'+ user.relation[0].IdCompanyLink +'.png'" class="h-6">
                    <img v-else :src="'/img/LOGOGRUPOBLANCO.png'" class="h-6">
                    <div class="h-8 w-8 ml-8 flex flex-col justify-center items-center">
                        <Request  model="Auth" action="logout" @success="onLogout" v-slot="{ request, loading:logoutloading }">
                            <div class="h-6 w-6 rounded-full shadow_box bg-videowall_bg cursor-pointer flex flex-col justify-center items-center" 
                                @click="request" :disabled="logoutloading">
                                <i class="mdi mdi-power text-white text-sm"></i>
                            </div>
                        </Request>
                    </div>
                </div>
            </div>

            <div class="relative h-full w-full bg-videowall_bg flex flex-row justify-between px-4 pt-8">
                <!-- Grupo -->
                <div class="h-full w-160 pr-2 py-4">
                    <div class="h-full w-full bg-videowall_module shadow_box rounded-xl flex flex-col px-2 py-4">
                        <group-info @expandgroup="expandgroup" />
                    </div>
                </div>
                <!-- Empresa -->
                <div class="h-full w-160 px-2 py-4">
                    <div class="h-full w-full bg-videowall_module shadow_box rounded-xl flex flex-col px-2 py-4">
                        <companies @expandcompany="expandcompany" @openCompany="openCompany" :menucompanyopen="menucompanyopen" 
                            @opencomercials="opencomercials" />
                    </div>
                </div>

                <!-- Tops -->
                <div class="h-full w-160 pl-2 py-4">
                    <div class="h-full w-full bg-videowall_module shadow_box rounded-xl flex flex-col px-2 py-4">
                        <!-- Tops -->
                        <div class="flex-1 mb-4 rounded-xl flex flex-col overflow-hidden">
                            <div class="h-6 flex flex-row justify-between items-center px-2">
                                <span class="text-lg text-white font-semibold">TOPS 
                                    <small v-if="fechatops" class="text-videowall_gray">
                                        ({{ fechatops.dateFrom | moment('DD MMMM YYYY') }} - {{ fechatops.dateTo | moment('DD MMMM YYYY') }})
                                    </small>
                                </span>
                            </div>
                            <div class="h-6 w-full px-2 flex flex-row justify-between items-center mt-2">
                                <span class="text-md mr-1 text-white mr-2">{{ $t('dimension') }}:</span>
                                <select-dimensions class="w-44 mr-auto" />
                                <span v-if="dimension !== 'Country' && dimension !== 'Countrytops' && dimension !== 'Province'
                                    && dimension !== 'Provincetops' && dimension !== 'Continent' && dimension !== 'Continenttops'"
                                    class="text-md mr-1 text-white mr-2">{{ $t('place') }}:</span>
                                <select-place v-if="dimension !== 'Country' && dimension !== 'Countrytops' && dimension !== 'Province'
                                    && dimension !== 'Provincetops' && dimension !== 'Continent' && dimension !== 'Continenttops'"
                                    class="w-44" />
                            </div>
                            <div v-if="typePlace && (dimension !== 'Country' && dimension !== 'Countrytops' && dimension !== 'Province'
                                && dimension !== 'Provincetops' && dimension !== 'Continent' && dimension !== 'Continenttops')"
                                class="h-6 w-full px-2 mt-2">
                                <search-place />
                            </div>
                            <div class="flex-1 overflow-hidden videowall_scrollbar py-2 mt-2">
                                <All :model="model" :immediate="false" :query="query" :data.sync="fechatops" v-slot="{data,loading}" ref="tops">
                                    <div v-if="!loading" class="h-full overflow-hidden flex flex-col">
                                        <div v-if="companyselected" class="h-8 flex flex-row">
                                            <div class="h-full w-1/4 flex flex-row justify-center items-center">
                                                <span class="text-sm text-videowall_gray">{{ translate(topindicatorname) }}</span>
                                            </div>
                                            <div class="h-full w-1/4 flex flex-row justify-center items-center">
                                                <span class="text-videowall_gray text-sm">{{ $t('growth') }}</span>
                                            </div>
                                            <div class="h-full w-1/4 flex flex-row justify-center items-center">
                                                <span class="text-videowall_gray text-sm">{{ $t('weight') }}</span>
                                            </div>
                                            <div class="h-full w-1/4 flex flex-row justify-center items-center">
                                                <span class="text-videowall_gray text-sm">Vs {{ $t('group') }}</span>
                                            </div>
                                        </div>
                                        <div v-else class="h-8 flex flex-row">
                                            <div class="h-full w-1/3 flex flex-row justify-center items-center">
                                                <span class="text-sm text-videowall_gray">{{ translate(topindicatorname) }}</span>
                                            </div>
                                            <div class="h-full w-1/3 flex flex-row justify-center items-center">
                                                <span class="text-videowall_gray text-sm">{{ $t('growth') }}</span>
                                            </div>
                                            <div class="h-full w-1/3 flex flex-row justify-center items-center">
                                                <span class="text-videowall_gray text-sm">{{ $t('weight') }}</span>
                                            </div>
                                        </div>
                                        <div v-if="data && data.table && data.table.length" class="flex-1 overflow-auto">
                                            <top v-for="(el,index) in data.table" :key="index" :data="el" />
                                        </div>
                                        <div v-else class="flex-1 flex flex-col justify-center items-center">
                                            <span class="text-videowall_gray">{{ $t('noData') }}</span>
                                        </div>
                                    </div>
                                    <div v-else class="h-full relative">
                                        <loader-videowall :loading="loading" />
                                    </div>
                                </All>
                            </div>
                        </div>

                        <!-- Mapa -->
                        <div class="bg-videowall_box rounded-xl flex flex-col overflow-hidden"
                        :class="{'h-80 p-2': (dimension === 'Country' || dimension === 'Countrytops' || dimension === 'Province'
                        || dimension === 'Provincetops' || dimension === 'Continent' || dimension === 'Continenttops'),
                        'h-0': (dimension !== 'Country' && dimension !== 'Countrytops' && dimension !== 'Province' && dimension !== 'Provincetops'
                        && dimension !== 'Continent' && dimension !== 'Continenttops')}"
                        style="transition: all .3s">
                            <div class="h-8 flex flex-row justify-end items-center px-2"><filter-workspace /></div>
                            <div class="flex-1 p-2">
                                <All :model="modelmap" :immediate="false" :query="querymaps" v-slot="{data: mapdata, loading: loadmap}" ref="maps">
                                    <div v-if="!loadmap && mapdata" class="h-full">
                                        <videowallmap v-if="(dimension === 'Country') && !growthmaps" :data="mapdata.table" refMap="mapa" />
                                        <videowallmapgrowth v-if="(dimension === 'Country') && growthmaps" :data="mapdata.table" refMap="mapa" />
                                        <videowallmapspain v-if="(dimension === 'Province') && !growthmaps" :data="mapdata.table" refMap="mapaSpain" />
                                        <videowallmapspaingrowth v-if="(dimension === 'Province') && growthmaps"  :data="mapdata.table" refMap="mapaSpain" />
                                        <videowallmapcontinents v-if="(dimension === 'Continent') && !growthmaps" refMap="mapaContinents" :data="mapdata.table" />
                                        <videowallmapcontinentsgrowth v-if="(dimension === 'Continent') && growthmaps" refMap="mapaContinents" :data="mapdata.table" />
                                    </div>
                                    <div v-else class="h-full relative">
                                        <loader-map :loading="loadmap" />
                                    </div>
                                </All>
                            </div>
                            <!-- <div class="h-8 flex flex-row justify-between items-center px-4 bg-videowall_bg">
                                <span class="mr-2 font-semibold" :class="{'text-good': !growthmap, 'text-white': growthmap}">{{$t('weight')}}</span>
                                <el-switch
                                v-model="growthmap"
                                active-color="#13ce66"
                                inactive-color="#13ce66">
                                </el-switch>
                                <span class="ml-2 font-semibold" :class="{'text-good': growthmap, 'text-white': !growthmap}">{{$t('growth')}}</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <expand-group-modal v-if="expandgroupmenu" @groupmodal="groupmodal" />
            <expand-company-modal v-if="expandcompanymenu" @companymodal="companymodal" />
            <filters-modal v-if="filtersmenu" :expanded="expanded" @closemodal="closemodal" />
            <expand-comerials-modal v-if="modalcomercials" :saletype="SaleType" @comercialsmodal="comercialsmodal" />
            <!-- <div class="relative h-full w-full flex flex-row"> -->
                <!-- mapa -->
                <!-- <div class="h-full w-full absolute top-0 left-0 z-0 flex flex-col justify-center items-center">
                    <div class="h-170 w-180">
                        <All :model="modelmap" :immediate="false" :query="querymaps" v-slot="{data:mapdata, loading:loadmap}" ref="maps">
                            <div v-if="!loadmap && mapdata" class="h-full">
                                <videowallmap v-if="(typeplacename == 'Todos los Paises' || typeplacename == 'Pais' || typeplacename == null) 
                                    && growthmap" :data="mapdata.table" refMap="mapa" />
                                <videowallmapgrowth v-if="(typeplacename == 'Todos los Paises' || typeplacename == 'Pais' || typeplacename == null) 
                                    && !growthmap" :data="mapdata.table" refMap="mapa" />
                                <videowallmapspain v-if="(typeplacename == 'Todas las Provincias' || typeplacename == 'Provincias') 
                                    && growthmap"  :data="mapdata.table" refMap="mapaSpain" />
                                <videowallmapspaingrowth v-if="(typeplacename == 'Todas las Provincias' || typeplacename == 'Provincias') 
                                    && !growthmap"  :data="mapdata.table" refMap="mapaSpain" />
                                <videowallmapcontinents v-if="(typeplacename == 'Todos los Continentes' || typeplacename == 'Continente') 
                                    && growthmap" refMap="mapaContinents" :data="mapdata.table" />
                                <videowallmapcontinentsgrowth v-if="(typeplacename == 'Todos los Continentes' || typeplacename == 'Continente') 
                                    && !growthmap" refMap="mapaContinents" :data="mapdata.table" />
                            </div>
                            <div v-else class="h-full relative">
                                <loader-map :loading="loadmap"></loader-map>
                            </div>
                        </All>
                    </div>
                    <div class="h-20 w-1/5 flex flex-row justify-center items-center">
                        <span class="mr-2 font-semibold" :class="{'text-good': !growthmap, 'text-white': growthmap}">{{$t('weight')}}</span>
                        <el-switch
                        v-model="growthmap"
                        active-color="#13ce66"
                        inactive-color="#13ce66">
                        </el-switch>
                        <span class="ml-2 font-semibold" :class="{'text-good': growthmap, 'text-white': !growthmap}">{{$t('growth')}}</span>
                    </div>
                </div> -->
                <!-- modulo izquierda -->
                <!-- <div class="h-auto w-139 flex flex-col mt-14 ml-6 flex flex-col z-10">
                    <group-info @expandgroup="expandgroup"></group-info>
                    <companies @expandcompany="expandcompany" @openCompany="openCompany" :menucompanyopen="menucompanyopen" 
                        @opencomercials="opencomercials" />
                </div> -->
                <!-- modulo derecha -->
                <!-- <div class="h-full w-139 flex flex-col mt-14 mr-6 ml-auto flex flex-col z-10 pb-4">
                    <div class="h-auto min-h-36 max-h-242 bg-videowall_module rounded-xl flex flex-col shadow_box" style="transition: all .3s">
                        <div class="h-6 flex flex-row justify-between items-center px-2 mt-4">
                            <span class="text-lg text-white font-semibold">TOPS <small v-if="fechatops">({{fechatops.dateFrom | moment('DD MMMM YYYY')}} - {{fechatops.dateTo | moment('DD MMMM YYYY')}})</small></span>
                        </div>
                        <div class="h-6 w-full px-2 flex flex-row justify-between items-center mt-2">
                            <span class="text-md mr-1 text-white mr-2">{{$t('dimension')}}:</span>
                            <select-dimensions class="w-44 mr-auto"></select-dimensions>
                            <span v-if="dimension != 'Country' && dimension != 'Countrytops' && dimension != 'Province' 
                                && dimension != 'Provincetops' && dimension != 'Continent' && dimension != 'Continenttops'" 
                                class="text-md mr-1 text-white mr-2">{{$t('place')}}:</span>
                            <select-place v-if="dimension != 'Country' && dimension != 'Countrytops' && dimension != 'Province' 
                                && dimension != 'Provincetops' && dimension != 'Continent' && dimension != 'Continenttops'" class="w-44">
                            </select-place>
                        </div>
                        <div v-if="typePlace && (dimension != 'Country' && dimension != 'Countrytops' && dimension != 'Province' 
                            && dimension != 'Provincetops' && dimension != 'Continent' && dimension != 'Continenttops')" 
                            class="h-6 w-full px-2 mt-2 z-30">
                            <search-place></search-place>
                        </div>
                        <div class="flex-1 overflow-hidden videowall_scrollbar py-2 mt-2">
                            <All :model="model" :immediate="false" :query="query" :data.sync="fechatops" v-slot="{data,loading}" ref="tops">
                                <div v-if="!loading" class="h-full overflow-hidden flex flex-col">
                                    <div class="flex-1 overflow-auto">
                                        <div v-for="(el,index) in data.table" :key="index" class="h-20 mb-1 flex flex-col overflow-hidden 
                                            rounded-xl bg-videowall_box p-2 mx-2">
                                            <div class="h-auto flex flex-row justify-start items-center py-1">
                                                <span class="text-white text-sm font-semibold text-center">{{el.name}}</span>
                                            </div>
                                            <div class="flex-1 w-full flex flex-row">
                                                <div class="h-full w-4/12 flex flex-col justify-center items-center">
                                                    <span class="text-gray text-xs -mb-1">{{topindicatorname}}</span>
                                                    <span class="text-sm text-white font-semibold">{{el.value | reduceBigNumbers()}} 
                                                    <span v-html="unit"></span></span>
                                                </div>
                                                <div class="h-full w-4/12 flex flex-col justify-center items-center">
                                                    <span class="text-gray text-xs -mb-1">{{$t('growth')}}</span>
                                                    <span class="text-sm font-semibold" 
                                                        :class="{'text-good': el.growth >= 0, 'text-bad': el.growth < -5, 'text-med': el.growth > -5 
                                                        && el.growth < 0 }"><span v-if="el.growth > 0">+</span>{{el.growth | reduceBigNumbers()}}%
                                                    </span>
                                                </div>
                                                <div class="h-full w-4/12 flex flex-col justify-center items-center">
                                                    <span class="text-gray text-xs -mb-1">{{$t('weight')}}</span>
                                                    <span class="text-sm font-semibold text-white">{{el.weighing | reduceBigNumbers()}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="h-full relative">
                                    <loader-videowall :loading="loading" />
                                </div>
                            </All>
                        </div>
                    </div>
                </div> -->

                <!-- <expand-group-modal v-if="expandgroupmenu" @groupmodal="groupmodal" />
                <expand-company-modal v-if="expandcompanymenu" @companymodal="companymodal" />
                <filters-modal v-if="filtersmenu" :expanded="expanded" @closemodal="closemodal" />
                <expand-comerials-modal v-if="modalcomercials" :saletype="SaleType" @comercialsmodal="comercialsmodal" /> -->

            <!-- </div> -->

        </div>
        <div v-else class="h-screen w-screen bg-videowall_bg relative flex flex-row relative flex flex-col justify-center items-center">
            <img :src="'/img/LOGOGRUPOBLANCO.png'" class="h-12 w-32">
            <span class="text-white text-lg font-semibold mt-2">{{ $t('videowallPermissions') }}</span>
            <Request  model="Auth" action="logout" @success="onLogout" v-slot="{ request, loading: logoutloading }">
                <span class="text-lg text-purple font-semibold" @click="request" :disabled="logoutloading">{{ $t('closeSesion') }}</span>
            </Request>
        </div>
    </div>

</template>

<script>
import { All,Request } from '@/api/components';
import { state,actions } from '@/store';
import videowallmap  from '@/components/videowallmap.vue' ;
import solidgaugevideowall from '@/components/solidgaugevideowall.vue';
import countries from '@/components/videowall/countries.vue';
import comercials from '@/components/videowall/comercials.vue';
import familys from '@/components/videowall/familys.vue';
import clients from '@/components/videowall/clients.vue';
import formats from '@/components/videowall/formats.vue';
import groupInfo from '@/components/videowall/groupInfo.vue';
import companies from '@/components/videowall/companies.vue';
import expandGroupModal from '@/components/videowall/expandGroupModal.vue';
import expandCompanyModal from '@/components/videowall/expandGroupCompany.vue';
import filtersModal from '@/components/videowall/filtersModal.vue';
import selectDimensions from '@/components/videowall/selectDimensions.vue';
import selectPlace from '@/components/videowall/selectPlace.vue';
import videowallmapspain from '@/components/videowall/videowallmapspain.vue';
import videowallmapcontinents from '@/components/videowall/videowallmapcontinents.vue';
import loaderVideowall from '@/components/videowall/loaderVideowall.vue';
import loaderMap from '@/components/videowall/loaderMap.vue';
import zone from '@/components/videowall/zone.vue';
import expandComerialsModal from '@/components/videowall/expandComercialsModal.vue';
import searchPlace from '@/components/videowall/searchPlace.vue';
import videowallmapgrowth  from '@/components/videowallmapgrowth.vue' ;
import videowallmapspaingrowth  from '@/components/videowall/videowallmapspaingrowth.vue';
import videowallmapcontinentsgrowth  from '@/components/videowall/videowallmapcontinentsgrowth.vue';
import FilterWorkspace from '@/components/videowall/FilterWorkspace.vue';
import top from '@/components/videowall/top.vue';

    export default {
        name: 'videowall',
        components: {
            videowallmap ,
            solidgaugevideowall,
            All,
            Request,
            countries,
            comercials,
            familys,
            clients,
            formats,
            groupInfo,
            companies,
            expandGroupModal,
            expandCompanyModal,
            filtersModal,
            selectDimensions,
            videowallmapspain,
            videowallmapcontinents,
            loaderVideowall,
            zone,
            expandComerialsModal,
            selectPlace,
            loaderMap,
            searchPlace,
            videowallmapgrowth,
            videowallmapspaingrowth,
            videowallmapcontinentsgrowth,
            FilterWorkspace,
            top
        },
        data() {
            return {
                option_filter: 'facturacion',
                datasolidgauge: {
                    actual:60,
                    prev:40
                },
                menucompanyopen: false,
                expandcompanymenu: false,
                filtersmenu: false,
                // period: null,
                topplaces: null,
                expanded: false,
                expandgroupmenu: false,
                dimensionOption: null,
                topindicators: null,
                opendimensions: false,
                first: false,
                spainData:[
                    ['es-va',800]
                ],
                tops: null,
                newdate: null,
                comercialbutton: false,
                modalcomercials: false,
                activebuttons: false,
                activecomercials: null,
                SaleType:null,
                periods: [
                    {
                        name: this.$t("monthly"),
                        value: 'month'
                    },
                    {
                        name: this.$t("quarterly"),
                        value: 'quarter'
                    },
                    {
                        name: this.$t("annual"),
                        value: 'year'
                    }
                ],
                places: [
                    {
                        name: 'Continente',
                        value: 'Continenttops'
                    },
                    {
                        name: 'Pais',
                        value: 'Countrytops'
                    },
                    {
                        name: 'Provincias',
                        value: 'Provincetops'
                    }
                ],
                topplaces: null,
                fechatops: null,
                growthmap:null

            }
        },
        methods: {
            translate(word) {
                switch (word) {
                    case 'Metros cuadrados':
                        return this.$t("squareMeters")
                        break;
                    case 'Precio medio':
                        return this.$t("averagePrice")
                        break;
                    case 'Facturación':
                        return this.$t("billing")
                        break;
                    case 'Margen':
                        return this.$t("margin")
                        break;
                }
            },
            openCompany(company) {
                if (!this.expandcompanymenu) { this.menucompanyopen = !this.menucompanyopen }
                else {
                    if (this.rol !== 'Gerente') { this.expandcompanymenu = !this.expandcompanymenu }
                }
            },
            expandgroup() { this.expandgroupmenu = !this.expandgroupmenu },
            expandcompany() { this.expandcompanymenu = !this.expandcompanymenu },
            groupmodal() { this.expandgroupmenu = !this.expandgroupmenu },
            comercialsmodal() {
                if (this.modalcomercials) {
                    this.activecomercials = null
                    this.modalcomercials = !this.modalcomercials
                }
            },
            companymodal() { this.expandcompanymenu = !this.expandcompanymenu },
            closemodal() { this.expanded = !this.expanded },
            changeoption(option) { this.category_filter = option },
            date() { this.newdate = new Date() },
            opencomercials(option, saletype) {
                if (this.modalcomercials) {
                    if (this.activecomercials === option) {
                        this.modalcomercials = false,
                        this.activecomercials = null
                        this.SaleType = null
                    }
                    else {
                        this.SaleType = saletype
                        this.activecomercials = option
                    }
                }
                else {
                    this.modalcomercials = !this.modalcomercials
                    this.SaleType = saletype
                    this.activecomercials = option
                }
            },
            onLogout() {
                actions.resetAuth();
                this.$router.push({ name: 'login' });
            },
            dropdownPeriod(option) { actions.setVideowallPeriod(option) },
            dropdownTypePlace(option){
                actions.setVideowallTypePlace(option)
                for (let index = 0; index < this.places.length; index++) {
                    if (this.places[index].value === option) { actions.setVideowallTypePlaceName(this.places[index].name) }
                }
                setTimeout(() => {
                   this.$refs['refplacess'].request();
                }, 100);
            }
        },
        computed: {
            periodo() {
                switch (state.videowallPeriod) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            typeplacename() { return state.videowallTypePlaceName },
            topindicatorname() { return state.videowallIndicatorName },
            placeName() { return state.videowallPlaceName },
            typemap() {
                if (this.typeplacename === 'Provincias' || this.typeplacename === 'Todas las Provincias') { return 'spainmap' }
                else { return 'worldMapData' }
            },
            model() {
                if (state.videowallCompany && state.videowallCompany.Guid) { return 'Companytops' }
                else { return 'Grouptops' }
            },
            query() {
                if (state.videowallCompany) {
                    return {
                        period: state.videowallPeriod,
                        Dimension: state.videowallIndicator,
                        Group: state.videowallDimension,
                        Company: state.videowallCompany.Guid,
                        Place: this.videowallplace,
                        PlaceId: state.videowallPlace
                    }
                }
                else {
                    return {
                        period: state.videowallPeriod,
                        Dimension: state.videowallIndicator,
                        Group: state.videowallDimension,
                        Place: this.videowallplace,
                        PlaceId: state.videowallPlace
                    }
                }
            },
            category_filter() { return state.videowallDimension },
            category_filter_name() { return state.videowallDimensionName },
            companyselected() { return state.videowallCompany },
            dimension() { return state.videowallDimension },
            indicator() { return state.videowallIndicator },
            today() { return this.$moment().format("DD MMMM YYYY") },
            time() { return this.$moment().format("HH:MM:SS") },
            unit() {
                let unit = '€'
                if (state.videowallIndicator == 1) { unit = 'm<sup>2</sup>' }
                if (state.videowallIndicator == 3) { unit = '%' }
                if (state.videowallIndicator == 2) { unit = '€/m<sup>2</sup>' }
                return unit
            },
            querycomercial() {
                return {
                    period: state.videowallPeriod,
                    Dimension: state.videowallIndicator,
                    Group: state.videowallDimension,
                    Company: state.videowallCompany.Guid,
                    SaleType: this.SaleType
                }
            },
            modelmap() {
                if (state.videowallCompany && state.videowallCompany.Guid) { return 'MapDataVideowallCompany' }
                else { return 'MapDataVideowall' }
            },
            querymaps() {
                if (state.videowallCompany && state.videowallCompany.Guid) {
                    return {
                        period: state.videowallPeriod,
                        Dimension: state.videowallIndicator,
                        Group: this.groupvalue,
                        Company: state.videowallCompany.Guid,
                        // Place: this.videowallplace,
                        // PlaceId: state.videowallPlace
                    }
                }
                else {
                    return {
                        period: state.videowallPeriod,
                        Dimension: state.videowallIndicator,
                        Group: this.groupvalue,
                        // Place: this.videowallplace,
                        // PlaceId: state.videowallPlace
                    }
                }
            },
            groupvalue() {
                return state.videowallDimension
                // if(state.videowallDimension == 'Countrytops' || state.videowallDimension == 'Continenttops' || state.videowallDimension == 'Provincetops'){
                //     switch (state.videowallDimension) {
                //         case 'Countrytops':
                //             return 'Country'
                //             break;
                //         case 'Continenttops':
                //             return 'Continent'
                //             break;
                //         case 'Provincetops':
                //             return 'Province'
                //             break;
                //     }
                // } else {
                //     return state.videowallDimension
                // }
            },
            rol() { return state.user.rol.Name },
            user() { return state.user },
            period() { return state.videowallPeriod },
            typePlace() { return state.videowallTypePlace },
            place() { return state.videowallPlace },
            placeparam() {
                switch (state.videowallTypePlaceName) {
                    case 'Continente':
                        return 'Continent'
                        break;
                    case 'Pais':
                        return 'Country'
                        break;
                    case 'Provincias':
                        return 'Province'
                        break;
                }

            },
            videowallplace() {
                switch (state.videowallTypePlace) {
                    case 'Continenttops':
                        return 'Continent'
                        break;
                    case 'Countrytops':
                        return 'Country'
                        break;
                    case 'Provincetops':
                        return 'Province'
                        break;
                }
            },
            growthmaps() { return state.videowallGrowth },
            refreshTime(){ return state.refreshTime }
        },
        watch: {
            filtersmenu(n,o) {
                if (n) {
                    setTimeout(() => {
                        this.expanded = !this.expanded
                    }, 100);
                }
            },
            expanded(n,o) {
                if (!n) {
                    setTimeout(() => {
                        this.filtersmenu = !this.filtersmenu
                    }, 301);
                }
            },
            category_filter() { this.tops = state.videowallDimension },
            tops() { this.$refs['tops'].request() },
            companyselected() {
                setTimeout(() => {
                    this.$refs['tops'].request()
                    this.$refs['maps'].request()
                }, 100);
            },
            dimension() {
                setTimeout(() => {
                    this.$refs['tops'].request()
                    this.$refs['maps'].request();
                }, 100);
            },
            indicator() {
                setTimeout(() => {
                    this.$refs['tops'].request()
                    this.$refs['maps'].request();
                }, 100);
            },
            menucompanyopen(n,o) {
                if (n) {
                    setTimeout(() => {
                        this.activebuttons = true
                    }, 200);
                }
                else {
                    setTimeout(() => {
                        this.activebuttons = false
                    }, 0);
                }
            },
            activebuttons(n,o) {
                if (n) {
                    setTimeout(() => {
                        this.comercialbutton = true
                    }, 500);
                }
                else {
                    setTimeout(() => {
                        this.comercialbutton = false
                    }, 0);
                }
            },
            SaleType() {
                setTimeout(() => {
                    // this.$refs['zones'].request();
                }, 100);
            },
            placeName() {
                setTimeout(() => {
                    this.$refs['maps'].request();
                }, 100);
            },
            typeplacename() {
                setTimeout(() => {
                    this.$refs['maps'].request();
                }, 100);
            },
            periodo() {
                setTimeout(() => {
                    this.$refs['tops'].request()
                    this.$refs['maps'].request();
                }, 100);
            },
            typePlace() {
                if (!this.place) {
                    setTimeout(() => {
                        this.$refs['tops'].request()
                        this.$refs['maps'].request();
                    }, 100);
                }
            },
            place() {
                setTimeout(() => {
                    this.$refs['tops'].request()
                    this.$refs['maps'].request();
                }, 100);
            }
        },
        mounted() {
            this.tops = this.category_filter
            setInterval(() => {
                this.date();
            }, 1000);
            setTimeout(() => {
                this.$refs['maps'].request()
            }, 100);
            this.growthmap = state.videowallGrowth
            setInterval(() => {
                if(this.rol === 'Gerente'){
                    // this.menucompanyopen = true
                }
                setTimeout(() => {
                    this.$refs['maps'].request()
                    this.$refs['tops'].request()
                }, 100);
            }, this.refreshTime);
            // if(state.user.rol.Name == 'Gerente'){
            //     this.expandcompanymenu = true
            // }
        }
    };
</script>

<style>

    html {
        @media(min-width: 769px){
            font-size: 0.8333333333vw;
        }
    }

</style>
