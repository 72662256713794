<template>
    
    <div class="h-16 flex flex-row justify-start items-center  bg-videowall_box rounded-xl mt-1">
        <All model="Groupotherbubbles" :immediate="true" :query="query" :data.sync="otherbubbles" v-slot="{data:otherbubbles, loading:loadingbubbles23}"></All>
        <div v-if="!modal" class="h-full w-1/5 flex flex-col justify-center items-start px-2">
            <span class="text-white text-md font-semibold text-left">{{$t('orders')}} <br><small class="text-videowall_gray">({{date | moment('DD MMM YY')}})</small></span>
        </div>
        <div v-else class="h-full w-1/5 flex flex-col justify-center items-start px-2">
            <span class="text-white text-md font-semibold text-left">{{$t('orders')}}  <small class="text-videowall_gray">({{date | moment('DD MMM YY')}})</small></span>
        </div>
        <div v-if="otherbubbles && !modal" class="h-full w-2/5 flex flex-col justify-center items-center">
            <div class="h-6 pt-1 flex flex-col justify-center items-center w-full">
                <span class="text-sm text-videowall_gray">{{$t('orders')}} {{$t('day')}}</span>
            </div>
            <div class="h-10 flex flex-row justify-center items-center w-full">         
                <span class="text-md text-white font-semibold mr-4">{{otherbubbles.BubblePendingOrder.Day | reduceBigNumbers(2)}} €</span>
                <span class="text-md text-white font-semibold">{{otherbubbles.BubblePendingOrder.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
            </div>
        </div>
        <div v-if="otherbubbles && modal" class="h-full w-2/5 flex flex-row justify-start items-center">
            <span class="text-sm text-videowall_gray mr-4">{{$t('orders')}} {{$t('day')}}</span>
            <span class="text-md text-white font-semibold mr-4">{{otherbubbles.BubblePendingOrder.Day | reduceBigNumbers(2)}} €</span>
            <span class="text-md text-white font-semibold">{{otherbubbles.BubblePendingOrder.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
        </div>
        <div v-if="otherbubbles && !modal" class="h-full w-2/5 flex flex-col justify-center items-center">
            <div class="h-6 pt-1 flex flex-col justify-center items-center w-full">
                <span class="text-sm text-videowall_gray">{{$t('orderBook')}}</span>
            </div>
            <div class="h-10 flex flex-row justify-center items-center w-full">         
                <span class="text-md text-white font-semibold mr-4">{{otherbubbles.BubblePendingOrder.Accumulated | reduceBigNumbers(2)}} €</span>
                <span class="text-md text-white font-semibold">{{otherbubbles.BubblePendingOrder.AccumulatedMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
            </div>
        </div>
        <div v-if="otherbubbles && modal" class="h-full w-2/5 flex flex-row justify-start items-center">
            <span class="text-sm text-videowall_gray mr-4">{{$t('orderBook')}}</span>
            <span class="text-md text-white font-semibold mr-4">{{otherbubbles.BubblePendingOrder.Accumulated | reduceBigNumbers(2)}} €</span>
            <span class="text-md text-white font-semibold">{{otherbubbles.BubblePendingOrder.AccumulatedMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
        </div>
    </div>

</template>

<script>
import { All,Request } from '@/api/components';
import { state, actions } from '@/store';

export default {
    props:['date', 'modal'],
    components:{
        All,
        Request
    },
    data() {
        return {
            otherbubbles:null
        }
    },
    computed: {
        query() {
            return {
                period: state.videowallPeriod
            }
        }
    }
}
</script>