<template>
    <div class="chart-wrap w-full h-full" ref="container-chart">
        <highmaps class="chart-container w-full h-full" :options="options" ref="mapCountry"></highmaps>
    </div>
</template>

<script>
import data from '../../utils/mapcontinents.js';
import {reduceBigNumbers} from '../../main.js'

    export default {
        props: ['data','refMap'],
        data() {
            return {
                options: {
                    chart: {
                        borderWidth: 0,
                        map: data,
                        backgroundColor: 'rgba(0,0,0,0)',
                        margin:[0,0,0,0]
                    },

                    credits:{
                        enabled: false
                    },

                    title: {
                        text: ''
                    },

                    subtitle: {
                        text: ''
                    },

                    legend: {
                        enabled: false
                    },

                    plotOptions:{
                        
                    },

                    tooltip:{
                        enabled: true,
                        formatter: function() {
                            return '<span class="font-semibold">' + this.point.name + '</span><br><span class="font-semibold" :style="color:#7070F0">'+ reduceBigNumbers(this.point.value) + '%</span>';
                        }
                    },

                    mapNavigation: {
                        enabled: false,
                    },

                    colorAxis:{
                        stops:[
                            [0,'#56526f'],
                            [0.01,'#56526F'],
                            [0.05,'#6661ae'],
                            [0.15,'#6a66c3'],
                            [0.5,'#6e6bd9'],
                            [1,'#7070f0']
                        ]
                    },

                    series: [
                        {
                            name: 'Data',
                            // data:[
                            //     ['eu', 0],
                            //     ['oc', 20],
                            //     ['af', 30],
                            //     ['as', 200],
                            //     ['na', 10],
                            //     ['sa', 135]
                            // ],
                            data:[],
                            joinBy: ['hc-key', 'code'],
                            nullColor: '#484848',
                            borderColor: '#2A2A2A'
                        }
                    ]
                }
            }
        },
        methods: {
            getData(){

                let chart = this.$refs['mapCountry'].chart;
                let countries = [];

                for (let index = 0; index < this.data.length; index++) {

                    if(this.data[index].iso){
                        
                        countries.push(
                            {
                                name: this.data[index].name,
                                code: this.data[index].iso.toLowerCase(),
                                value: this.data[index].weighing
                            }
                        )

                    }

                }

                chart.series[0].setData(countries)
                
            },
            colormap(value){

                if(value < 0){

                    if(value > -5){

                        return '#ffb52c'

                    } else {

                        return '#FF6154'

                    }

                } else {

                    return '#7beeb3'

                }

            }
        },
        watch: {
            data(){
                this.getData();
            }
        },
        mounted(){
            this.getData();
        }
    };
</script>
