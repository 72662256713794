<template>

    <All model="Groupcompanies" :immediate="false" :query="query" :data.sync="companis" v-slot="{data,loading}" ref="companies">
        <div v-if="!loading && data" class="h-full overflow-hidden">
            <div class="h-full overflow-hidden" :class="{'h-full': !menucompanyopen, 'h-full': menucompanyopen}" style="transition: all .3s;">
                <div v-if="!companySelected" class="h-full flex flex-col overflow-hidden">
                    <All model="Groupinfo" :immediate="true" :query="query" :data.sync="groupdate" v-slot="{data :info, loading: infoloading}">
                        <div v-if="!infoloading" class="h-6 flex flex-row justify-between items-center px-2">
                            <span class="text-lg font-semibold text-white">{{ $t('companies') }}
                                <small v-if="groupdate" class="text-videowall_gray">({{ groupdate.LastSaleDate | moment('DD MMM YY') }})</small>
                            </span>
                            <i class="mdi mdi-arrow-expand text-videowall_gray text-2xs cursor-pointer hidden" @click="expandgroup()"></i>
                        </div>
                    </All>

                    <!-- <div v-if="indicator == 'Precio medio' || indicator == 'Margen'" class="h-2 mt-2 flex flex-row">
                        <div class="h-full w-5/12 flex flex-row justify-start items-center px-2">

                        </div>
                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            <span class="text-3xs text-white">{{indicator}}</span>
                        </div>
                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            <span class="text-3xs text-white">Acum.</span>
                        </div>
                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            <span class="text-3xs text-white">{{$t('variation')}}</span>
                        </div>
                    </div>

                    <div v-else class="h-2 mt-2 flex flex-row">
                        <div class="h-full w-5/12 flex flex-row justify-start items-center px-2">

                        </div>
                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            <span class="text-3xs text-white">{{indicator}}</span>
                        </div>
                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            <span class="text-3xs text-white">Acum.</span>
                        </div>
                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            <span class="text-3xs text-white">Alb.</span>
                        </div>
                        <div class="h-full w-3/12 flex flex-row justify-center items-center">
                            <span class="text-3xs text-white">{{$t('variation')}}</span>
                        </div>
                    </div>

                    <div v-for="(el,index) in data" :key="index">

                        <div v-if="indicator == 'Precio medio' || indicator == 'Margen'" class="h-12 bg-videowall_box rounded-xl border flex flex-row cursor-pointer" :class="{'mt-1': index != 0, 'border-good':el[0].MonthVariation >= 0, 'border-bad':el[0].MonthVariation < 0 }" @click="openCompany(el[0])">

                            <div class="h-full w-5/12 flex flex-row justify-start items-center px-2">
                                <span class="text-sm text-white font-semibold">{{el[0].Name}}</span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[0].Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[0].Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm font-semibold" :class="{'text-bad': el[0].MonthVariation < 0, 'text-good': el[0].MonthVariation >= 0}">{{el[0].MonthVariation | reduceBigNumbers()}}%</span>
                            </div>

                        </div>

                        <div v-else class="h-12 bg-videowall_box rounded-xl border flex flex-row cursor-pointer" :class="{'mt-1': index != 0, 'border-good':el[0].MonthVariation >= 0, 'border-bad':el[0].MonthVariation < 0 }" @click="openCompany(el[0])">

                            <div class="h-full w-5/12 flex flex-row justify-start items-center px-2">
                                <span class="text-sm text-white font-semibold">{{el[0].Name}}</span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[0].Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[0].Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[0].DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm font-semibold" :class="{'text-bad': el[0].MonthVariation < 0, 'text-good': el[0].MonthVariation >= 0}">{{el[0].MonthVariation | reduceBigNumbers()}}%</span>
                            </div>

                        </div>

                        <div v-if="el[1] && (indicator == 'Precio medio' || indicator == 'Margen')" class="h-12 bg-videowall_box rounded-xl border flex flex-row cursor-pointer" :class="{'mt-1': index != 0, 'border-good':el[1].MonthVariation >= 0, 'border-bad':el[1].MonthVariation < 0 }" @click="openCompany(el[1])">

                            <div class="h-full my-auto w-5/12 flex flex-row justify-start items-center px-2">
                                <span class="text-sm text-white font-semibold">{{el[1].Name}}</span>
                            </div>
                            <div class="h-full my-auto w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[1].Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full my-auto w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[1].Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full my-auto w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm font-semibold" :class="{'text-bad': el[1].MonthVariation < 0, 'text-good': el[1].MonthVariation >= 0}">{{el[1].MonthVariation | reduceBigNumbers()}}%</span>
                            </div>

                        </div>

                        <div v-if="el[1] && (indicator == 'Facturación' || indicator == 'Metros cuadrados')" class="h-12 bg-videowall_box rounded-xl border flex flex-row cursor-pointer" :class="{'mt-1': index != 0, 'border-good':el[1].MonthVariation >= 0, 'border-bad':el[1].MonthVariation < 0 }" @click="openCompany(el[1])">

                            <div class="h-full my-auto w-5/12 flex flex-row justify-start items-center px-2">
                                <span class="text-sm text-white font-semibold">{{el[1].Name}}</span>
                            </div>
                            <div class="h-full my-auto w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[1].Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full my-auto w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[1].Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm text-white font-semibold">{{el[1].DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
                            </div>
                            <div class="h-full my-auto w-3/12 flex flex-row justify-center items-center">
                                <span class="text-sm font-semibold" :class="{'text-bad': el[1].MonthVariation < 0, 'text-good': el[1].MonthVariation >= 0}">{{el[1].MonthVariation | reduceBigNumbers()}}%</span>
                            </div>

                        </div>

                    </div> -->
                    <div class="flex-1 overflow-auto">
                        <company v-for="(el,index) in data" :key="index" :data="el" @openCompany="openCompany" />
                    </div>
                </div>
                <div v-else class="h-full">
                    <All model="Companyinfo" :immediate="true" ref="companyinfo" :query="queryinfocompany"
                        :data.sync="companydate" v-slot="{data: info2, loading: infoloading2}" />
                    <All model="VideowallCompany" :immediate="true" :query="querycompany" v-slot="{data: company, loading: loadingcompany}" ref="allcompany">
                        <div v-if="!loadingcompany && company" class="h-auto">
                            <div class="h-6 flex flex-row justify-between items-center px-2">
                                <div class="h-4 w-4 rounded-full bg-videowall_box flex flex-row justify-center items-center mr-2 cursor-pointer" @click="openCompany(null)">
                                    <i class="mdi mdi-chevron-left text-white text-xs"></i>
                                </div>
                                <All model="Groupinfo" :immediate="true" :query="query" :data.sync="groupdate" v-slot="{data: info, loading: infoloading}" ref="groupinfo" />
                                <span class="text-lg font-semibold text-white mr-auto">{{ companySelected.Name }}
                                    <small v-if="companydate && groupdate" class="text-videowall_gray">({{ groupdate.LastSaleDate | moment('DD MMM YY') }})</small>
                                </span>
                                <i class="mdi mdi-arrow-expand text-videowall_gray text-lg cursor-pointer" @click="expandcompany()"></i>
                            </div>
                            <div v-if="groupdate && companydate" class="flex-1 mt-4">
                                <bubbles-group-facturation v-if="indicatorSelected === 'Facturación'" :data="company" :modal="false" :date="companydate.LastSaleDate" />
                                <bubbles-group-metros v-if="indicatorSelected === 'Metros cuadrados'" :data="company" :modal="false" :date="companydate.LastSaleDate" />
                                <bubbles-group-preciomedio v-if="indicatorSelected === 'Precio medio'" :data="company" :modal="false" :date="companydate.LastSaleDate" />
                                <bubbles-group-margen v-if="indicatorSelected === 'Margen'" :data="company" :modal="false" :date="companydate.LastSaleDate" />
                                <All model="Companydetails" ref="companydetails" :immediate="true" :query="querycompany" v-slot="{data: datadetail, loading: loadingdetail}">
                                    <div v-if="!loadingdetail" class="h-72 mt-1 flex flex-col">
                                        <div class="h-1/2 w-full flex flex-row">
                                            <div class="h-full w-1/2 p-1">
                                                <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                                    <div class="h-8 flex flex-row justify-center items-center px-2">
                                                        <span class="font-semibold text-md text-white">{{ $t('activeClients') }}</span>
                                                    </div>
                                                    <div class="flex-1 flex flex-row">
                                                        <div class="h-full w-1/3 flex flex-col justify-center items-center">
                                                            <piechart :data="[['nuevos',datadetail.BubbleClient.New] ,
                                                                ['Recurrentes',datadetail.BubbleClient.YearActive-datadetail.BubbleClient.New]]" :videowall="true"
                                                            />
                                                        </div>
                                                        <div class="h-full w-2/3 flex flex-col justify-center items-start">
                                                            <div class="pr-2 w-full">
                                                                <div class="w-full h-6 flex flex-row pr-2 justify-between items-center">
                                                                    <div class="text-purple2 text-sm font-semibold">{{ $t('appellants') }}</div>
                                                                    <div class="text-sm text-white">{{datadetail.BubbleClient.YearActive-datadetail.BubbleClient.New | reduceBigNumbers(0)}}</div>
                                                                </div>

                                                                <div class="w-full h-6 flex flex-row pr-2 justify-between items-center">
                                                                    <div  class="text-turquesa text-sm font-semibold">{{ $t('new') }} {{year}}</div>
                                                                    <div class="text-sm text-white">{{ datadetail.BubbleClient.New | reduceBigNumbers(0)}}</div>
                                                                </div>

                                                                <div class="w-full h-6 flex flex-row pr-2 mt-1 justify-between items-center border-t border-white">
                                                                    <div class="text-sm font-semibold text-white">{{ $t('total') }} </div>
                                                                    <div class="text-sm text-white">{{ datadetail.BubbleClient.YearActive | reduceBigNumbers(0) }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="h-full w-1/2 p-1">
                                                <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                                    <div class="h-8 flex flex-row justify-center items-center px-2">
                                                        <span class="font-semibold text-md text-white">{{ $t('orders') }}</span>
                                                    </div>
                                                    <div class="flex-1 flex flex-row justify-center items-center">
                                                        <div class="h-full w-full flex flex-row">
                                                            <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                                <span class="text-sm text-videowall_gray">{{ $t('orders') }} {{ $t('day') }}</span>
                                                                <span class="text-md text-white font-semibold">{{ datadetail.BubblePendingOrder.Day | reduceBigNumbers(2) }} €</span>
                                                                <span class="text-md text-white font-semibold mb-1">
                                                                    {{ datadetail.BubblePendingOrder.DayMeters | reduceBigNumbers(2) }} m<sup>2</sup>
                                                                </span>
                                                            </div>
                                                            <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                                <span class="text-sm text-videowall_gray ">{{ $t('portfolio') }}</span>
                                                                <span class="text-md text-white font-semibold">{{ datadetail.BubblePendingOrder.Accumulated | reduceBigNumbers(2)}} €</span>
                                                                <span class="text-md text-white font-semibold">
                                                                    {{ datadetail.BubblePendingOrder.AccumulatedMeters | reduceBigNumbers(2) }} m<sup>2</sup>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="h-1/2 w-full flex flex-row">
                                            <div class="h-full w-1/2 p-1">
                                                <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                                    <div class="h-8 flex flex-row justify-center items-center px-2">
                                                        <span class="font-semibold text-md text-white">{{ $t('loadingOrders') }} <small>({{ $t('today') }} )</small></span>
                                                    </div>
                                                    <div class="flex-1">
                                                        <div class="flex-1 flex flex-col">
                                                            <div class="h-16 w-full flex flex-row mb-auto">
                                                                <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                                    <span class="text-sm text-videowall_gray">{{ $t('totalOperational')}}</span>
                                                                    <span class="text-md text-white font-semibold">
                                                                        {{ datadetail.BubbleOrders.OperativeOrdersToday | reduceBigNumbers(2) }} €
                                                                    </span>
                                                                </div>
                                                                <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                                    <span class="text-sm text-videowall_gray ">OC {{$t('next')}}. {{$t('day')}}</span>
                                                                    <span class="text-md text-white font-semibold">{{datadetail.BubbleOrders.OperativeOrdersNextDay | reduceBigNumbers(2)}} €</span>
                                                                </div>
                                                            </div>
                                                            <div class="h-auto w-ful flex flex-row justify-between items-center px-4">
                                                                <div class="font-bold text-videowall_gray text-sm">{{ $t('totalsOC')}}</div>
                                                                <div v-if="datadetail.BubbleOrders.OperativeOrdersTodayTomorrow" class="text-sm font-bold text-white">
                                                                    {{ datadetail.BubbleOrders.OperativeOrdersTodayTomorrow | reduceBigNumbers(0) }}
                                                                </div>
                                                                <div v-else class="text-sm font-bold text-white">-</div>
                                                            </div>
                                                            <div class="h-auto w-full flex flex-row justify-between items-center px-4">
                                                                <div class="font-bold text-videowall_gray text-sm">{{ $t('lastSynchronization') }}</div>
                                                                <div v-if="datadetail.BubbleOrders.SyncTime" class="text-sm font-bold text-white">{{ datadetail.BubbleOrders.SyncTime | moment('HH:mm') }}</div>
                                                                <div v-else class="text-sm font-bold text-white">-</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="h-full w-1/2 p-1">
                                                <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                                    <div class="h-8 flex flex-row justify-center items-center px-2">
                                                        <span class="font-semibold text-md text-white">{{ $t('deliveryNotesDay') }}</span>
                                                    </div>
                                                    <div class="flex-1 flex flex-col">
                                                            <div class="flex-1 w-full flex flex-row">
                                                                <div class="h-full w-full flex flex-col justify-center items-center">
                                                                    <span class="text-sm text-videowall_gray">{{ $t('current') }}</span>
                                                                    <span class="text-md text-white font-semibold">{{ datadetail.BubbleDeliveryNoteOrders.Day | reduceBigNumbers(2) }} €</span>
                                                                    <span class="text-md text-white font-semibold">
                                                                        {{ datadetail.BubbleDeliveryNoteOrders.DayMeters | reduceBigNumbers(2) }} m<sup>2</sup>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="h-8 mt-auto w-full flex flex-row justify-between items-center px-4">
                                                                <div class="font-bold text-videowall_gray text-sm">{{ $t('lastSynchronization') }}</div>
                                                                <div class="text-sm font-bold text-white">{{ datadetail.BubbleDeliveryNoteOrders.SyncTime | moment('HH:mm') }}h</div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="h-44 w-31.2 rounded-xl bg-videowall_box">
                                            <div class="h-auto p-1 flex flex-row justify-start items-center">
                                                <span class="text-white text-center text-xs">{{$t('activeClients')}}</span>
                                            </div>

                                            <div class="h-24 w-24 mx-auto flex flex-row justify-center items-center relative">
                                                <piechart :data="[['nuevos',datadetail.BubbleClient.New] ,['Recurrentes',datadetail.BubbleClient.YearActive-datadetail.BubbleClient.New]]" :videowall="true"></piechart>
                                            </div>

                                            <div class="h-auto -mt-1">
                                                <div class="px-2">
                                                    <div class="text-xs text-darkgray text-center flex justify-between">
                                                        <div class="text-purple2 text-2xs font-semibold">{{$t('activeClients')}}</div>
                                                        <div class="text-xs text-white">{{datadetail.BubbleClient.YearActive-datadetail.BubbleClient.New | reduceBigNumbers()}}</div>
                                                    </div>
                                                    <div class="text-xs text-darkgray text-center flex justify-between">
                                                        <div  class="text-turquesa text-2xs font-semibold">{{$t('new')}} {{year}}</div>
                                                        <div class="text-xs text-white">{{ datadetail.BubbleClient.New| reduceBigNumbers()}}</div>
                                                    </div>
                                                    <div class="text-xs text-darkgray text-center flex justify-between border-t ">
                                                        <div class="text-2xs font-semibold">Total</div>
                                                        <div class="text-xs text-white">{{datadetail.BubbleClient.YearActive | reduceBigNumbers()}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="h-44 w-31.2 rounded-xl bg-videowall_box flex flex-col">
                                            <div class="h-auto p-1 flex flex-row justify-start items-center mb-1">
                                                <span class="text-white text-center text-xs">{{$t('orders')}}</span>
                                            </div>
                                            <div class="flex-1 flex flex-row justify-center items-center">

                                                <div class="h-28 w-28 rounded-full flex flex-col justify-center items-center bg-blue leading-tight -mt-5">
                                                    <span class="text-2xs text-dfont">{{$t('orders')}} {{$t('day')}}</span>
                                                    <span class="text-xs text-white font-semibold">{{datadetail.BubbleOrders.Day | reduceBigNumbers(2)}} €</span>
                                                    <span class="text-xs text-white font-semibold mb-1">{{datadetail.BubbleOrders.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
                                                    <span class="text-2xs text-dfont ">Cartera pedidos</span>
                                                    <span class="text-xs text-white font-semibold">{{datadetail.BubbleOrders.Accumulated | reduceBigNumbers(2)}} €</span>
                                                    <span class="text-xs text-white font-semibold">{{datadetail.BubbleOrders.AccumulatedMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="h-44 w-31.2 rounded-xl bg-videowall_box flex flex-col">
                                            <div class="h-auto p-1 flex flex-row justify-start items-center mb-1">
                                                <span class="text-white text-center text-xs">{{$t('loadingOrders')}}</span>
                                            </div>

                                            <div class="flex-1 flex flex-row justify-center items-center relative">

                                                <div class="h-28 w-28 rounded-full flex flex-col justify-center items-center bg-circle leading-tight p-1">
                                                    <div class="text-2xs text-white text-center">{{$t('totalOC')}}</div>
                                                    <div class="text-xs text-white font-bold mb-1">{{datadetail.BubblePendingOrder.OperativeOrdersToday | reduceBigNumbers()}} €</div>
                                                    <div class="text-2xs text-white text-center">OC prox. {{$t('day')}}</div>
                                                    <div class="text-xs text-white font-bold">{{datadetail.BubblePendingOrder.OperativeOrdersNextDay | reduceBigNumbers()}} €</div>
                                                </div>

                                            </div>
                                            <div class="h-auto flex flex-row justify-between items-center px-1">
                                                <div class="font-bold text-gray text-2xs">{{$t('totalOC')}}</div>
                                                <div class="text-xs font-bold text-white">{{datadetail.BubblePendingOrder.OperativeOrdersTodayTomorrow | reduceBigNumbers()}} €</div>
                                            </div>
                                            <div class="h-auto flex flex-row justify-between items-center px-1">
                                                <div class="font-bold text-gray text-2xs">Últ. Sinc.</div>
                                                <div v-if="datadetail.BubblePendingOrder.SyncTime" class="text-xs font-bold text-white">{{datadetail.BubblePendingOrder.SyncTime | moment('HH:mm')}}</div>
                                                <div v-else class="text-xs font-bold text-white">-</div>
                                            </div>
                                        </div>

                                        <div class="h-44 w-31.2 rounded-xl bg-videowall_box flex flex-col">
                                            <div class="h-auto p-1 flex flex-row justify-start items-center mb-1">
                                                <span class="text-white text-center text-xs">{{$t('deliveryNotesDay')}}</span>
                                            </div>

                                            <div class="flex-1 mb-2 mt-1 flex justify-center items-center">

                                                <div class="h-28 w-28 -mt-5 rounded-full flex flex-col justify-center items-center bg-circle leading-tight">
                                                    <span class="text-2xs text-white opacity-50 mb-1">Actual</span>
                                                    <span class="text-xs text-white font-bold ">{{datadetail.BubbleDeliveryNoteOrders.Day | reduceBigNumbers(2)}} €</span>
                                                    <span class="text-xs text-white font-bold ">{{datadetail.BubbleDeliveryNoteOrders.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
                                                </div>

                                            </div>
                                            <div class="h-auto flex flex-row justify-between items-center px-1">
                                                <div class="font-bold text-gray text-2xs">Últ. Sinc.</div>
                                                <div class="text-xs font-bold text-white">{{datadetail.BubbleDeliveryNoteOrders.SyncTime | moment('HH:mm')}}h</div>
                                            </div>
                                        </div> -->
                                    </div>
                                </All>

                                <div class="w-full h-10 mt-2 flex flex-row justify-between items-center">
                                    <div class="h-full w-1/2 rounded-md bg-videowall_module flex flex-row justify-center items-center cursor-pointer"
                                        @click="opencomercials('nacional', '24C8C714394211EA966B005056AEAA71')">
                                        <i class="mdi text-lg mr-2 mdi-account-multiple-outline text-purple"></i>
                                        <span class="text-md text-white font-semibold">{{ $t('commercial') }} {{ $t('national') }}</span>
                                    </div>

                                    <div class="h-full w-1/2 rounded-md bg-videowall_module flex flex-row justify-center items-center cursor-pointer"
                                        @click="opencomercials('exportacion', '24C85D60394211EA966B005056AEAA71')">
                                        <i class="mdi text-lg mr-2 mdi-account-multiple-outline text-blue"></i>
                                        <span class="text-md text-white font-semibold">{{ $t('commercial') }} {{ $t('export') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="h-full relative">
                            <loader :loading="loadingcompany" />
                        </div>
                    </All>
                </div>
            </div>
        </div>
        <div v-else class="h-full relative"><loader :loading="loading" /></div>
    </All>

</template>

<script>
import { All } from '@/api/components';
import { state,actions } from '@/store';
import solidgaugevideowall from '@/components/solidgaugevideowall.vue';
import loader from './loaderVideowall.vue';
import bubblesGroupFacturation from './bubblesGroupFacturation';
import bubblesGroupMetros from './bubblesGroupMetros.vue';
import bubblesGroupPreciomedio from './bubblesGroupPreciomedio.vue';
import bubblesGroupMargen from './bubblesGroupMargen.vue';
import piechart from './piechartvideowall.vue';
import company from './company.vue';

export default {
    props: ['data','menucompanyopen'],
    components: {
        solidgaugevideowall,
        loader,
        All,
        bubblesGroupFacturation,
        bubblesGroupMetros,
        bubblesGroupPreciomedio,
        bubblesGroupMargen,
        piechart,
        company
    },
    data() {
        return {
            datasolidgauge: {
                actual:60,
                prev:40
            },
            yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
            yesterday_general: this.$moment().subtract(1, 'days').format("DD MMMM YYYY"),
            companis: null,
            groupdate: null,
            companydate: null,
            year: this.$moment().format('YYYY')
        }   
    },
    methods: {
        expandcompany() { this.$emit('expandcompany') },
        openCompany(company) {
            if (company) {
                if (company.Guid) {
                    if (state.user.rol.Name === 'Propiedad' || state.idCompany === company.Guid) {
                        if (company == null) { actions.setVideowallCompany(null) }
                        else { actions.setVideowallCompany(company) }
                        this.$emit('openCompany',company)
                    }
                    if (company.Guid === '1BF7128E394211EA966B005056AEAA71') {
                        if (company.IndustrialGroup === true) { actions.setVideowallIndustrialGroup(false) }
                        else { actions.setVideowallIndustrialGroup(true) }
                    }
                    else { actions.setVideowallIndustrialGroup(null) }
                }
                else {
                    for (let index = 0; index < Object.values(this.companis).length; index++) {
                        if (Object.values(this.companis)[index][0].Guid === company.toUpperCase()) {
                            actions.setVideowallCompany(Object.values(this.companis)[index][0])
                            if (Object.values(this.companis)[index][0].Guid === '1BF7128E394211EA966B005056AEAA71') {
                                if (Object.values(this.companis)[index][0].IndustrialGroup === true) { actions.setVideowallIndustrialGroup(false) }
                                else { actions.setVideowallIndustrialGroup(true) }
                            }
                        else { actions.setVideowallIndustrialGroup(null) }
                        this.$emit('openCompany',Object.values(this.companis)[index][0])
                        }
                    }
                }
            }
            else {
                actions.setVideowallCompany(null),
                this.$emit('openCompany',company)
            }
        },
        opencomercials(type,id) { this.$emit('opencomercials', type, id) }
    },
    computed: {
        period() { return state.videowallPeriod },
        dimension() { return state.videowallIndicator },
        query() {
            return {
                period: state.videowallPeriod,
                Dimension: state.videowallIndicator,
                Place: this.videowallplace,
                PlaceId: state.videowallPlace
            }
        },
        queryinfocompany() {
            return {
                period: state.videowallPeriod,
                Company: state.videowallCompany.Guid
            }
        },
        querycompany() {
            return {
                period: state.videowallPeriod,
                Dimension: state.videowallIndicator,
                Company: state.videowallCompany.Guid,
                // Place: this.videowallplace,
                // PlaceId: state.videowallPlace,
                IndustrialGroup: this.IndustrialGroup
            }
        },
        companySelected() { return state.videowallCompany },
        indicatorSelected() { return state.videowallIndicatorName },
        indicatordata() {
            switch (state.videowallIndicatorName) {
                case 'Facturación':
                    return 'BubbleSale'
                    break;
                case 'Metros cuadrados':
                    return 'BubbleMeter'
                    break;
                case 'Precio medio':
                    return 'BubbleMediumPrice'
                    break;
                case 'Margen':
                    return 'BubbleMargin'
                    break;
            }
        },
        unit() {
            let unit = '€'
            if (state.videowallIndicator === 1) { unit = 'm<sup>2</sup>' }
            if (state.videowallIndicator === 3) { unit = '%' }
            return unit
        },
        indicator() { return state.videowallIndicatorName },
        videowallplace() {
            switch (state.videowallTypePlace) {
                case 'Continenttops':
                    return 'Continent'
                    break;
                case 'Countrytops':
                    return 'Country'
                    break;
                case 'Provincetops':
                    return 'Province'
                    break;
            }
        },
        place() { return state.videowallTypePlace },
        placeid() { return state.videowallPlace },
        IndustrialGroup() { return state.videowallIndustrialGroup },
        refreshTime() { return state.refreshTime }
    },
    watch: {
        period() {
            this.$refs['companies'].request();
        },
        companis() {
            if (state.user.rol.Name === 'Gerente') { this.openCompany(state.user.relation[0].IdCompany) }
        },
        indicator() {
            // setTimeout(() => {
            //     this.$refs['companies'].request();
            //     this.$refs['allcompany'].request();
            //     this.$refs['companyinfo'].request();
            // }, 100);
        },
        place() {
            // if(!this.place){
            //     this.$refs['companies'].request();
            // }
        },
        placeid() {
            // this.$refs['companies'].request();
        },
        indicatorSelected() {
            this.$refs['companies'].request();
        },
        menucompanyopen(n,o){

            // if(n == true){

            //     setTimeout(() => {
            //         this.$refs['allcompany'].request();
            //         this.$refs['companyinfo'].request();
            //     }, 100);

            // }

        },
        companySelected(){
            setTimeout(() => {
                this.$refs['companyinfo'].request();
            }, 200);
        }
    },
    mounted() {
        this.$refs['companies'].request();
        setInterval(() => {
            this.$refs['companies'].request();
        }, this.refreshTime);
        if (this.menucompanyopen) {
            setInterval(() => {
                this.$refs['companyinfo'].request();
                this.$refs['allcompany'].request();
                this.$refs['groupinfo'].request();
                this.$refs['companydetails'].request();
            }, this.refreshTime);
        }
        setTimeout(() => {
            if (state.user.rol.Name === 'Gerente') {
                this.expandcompany();
            }

        }, 100);

    }
}
</script>
