<template>

    <div class="h-full p-4 overflow-auto">

        <div v-for="(el,index) in data" :key="index" class="h-20 mb-2 flex flex-col overflow-hidden rounded-xl bg-videowall_box p-2">

            <div class="h-auto flex flex-row justify-start items-center py-1">
                <span class="text-white text-2xs font-semibold text-center">{{el.name}}</span>
            </div>

            <div class="h-10 w-full flex flex-row">

                <div class="h-full w-4/12 flex flex-col justify-center items-center">
                    <span class="text-gray text-4xs">{{$t('billing')}}</span>
                    <span class="text-xs text-white font-semibold">{{el.value | reduceBigNumbers()}}</span>
                </div>
                <div class="h-full w-4/12 flex flex-col justify-center items-center">
                    <span class="text-gray text-4xs">{{$t('growth')}}</span>
                    <span class="text-xs font-semibold" :class="{'text-good': el.growth > 0, 'text-bad': el.growth < 0}"><span v-if="el.growth > 0">+</span>{{el.growth | reduceBigNumbers()}}%</span>
                </div>
                <div class="h-full w-4/12 flex flex-col justify-center items-center">
                    <span class="text-gray text-4xs">{{$t('weight')}}</span>
                    <span class="text-xs font-semibold" :class="{'text-good': el.weighing > 0, 'text-bad': el.weighing < 0}"><span v-if="el.weighing > 0">+</span>{{el.weighing | reduceBigNumbers()}}%</span>
                </div>

            </div>

        </div>

        <!-- <div v-for="(el,index) in data" :key="index" class="h-16 overflow-hidden mb-2 flex flex-row overflow-hidden">

            <div class="h-16 w-16 rounded-xl mr-2 flex flex-row justify-center items-center border-2 border-purple bg-red_transparent">

                <span class="text-purple text-3xs font-semibold text-center">{{el.name}}</span>

            </div>

            <div class="h-full flex-1 rounded-xl bg-videowall_box flex flex-row overflow-hidden">

                <div class="h-full w-4/12 flex flex-col justify-center items-start pl-1 truncate">
                    <span class="text-white text-3xs font-semibold truncate">{{el.name}}</span>
                    <span class="text-gray text-4xs truncate">Dirección</span>
                    <span class="text-gray text-4xs truncate">Última compra</span>
                </div>

                <div class="h-full w-6/12">
                    <basiclinechart></basiclinechart>
                </div>

                <div class="h-full w-3/12 flex flex-col justify-center items-end pr-2">
                    <span class="text-gray text-3xs font-semibold truncate">22.37 K</span>
                    <span class="text-gray text-3xs font-semibold truncate">3.522 M<sup>2</sup></span>
                </div>

            </div>

        </div> -->

    </div>

</template>

<script>
import doubleChartVideowall from '@/components/doubleChartVideowall.vue';
import basiclinechart from '@/components/basiclinechart.vue';

export default {
    props:['data'],
    components:{
        doubleChartVideowall,
        basiclinechart
    }
}
</script>
