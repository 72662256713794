<template>
    
    <div class="absolute top-0 left-0 h-full w-full z-50 flex flex-row justify-start items-start" style="backdrop-filter:blur(5px)">
        <div class="h-72 w-90 ml-6 rounded-xl bg-videowall_module shadow_box p-4 overflow-hidden flex flex-col"
            :class="{'-mt-72': !expanded, 'mt-12': expanded}" style="transition: all .3s;">
            <div class="h-auto flex flex-row justify-between items-center mb-2 border-b border-white">
                <span class="text-white font-semibold">{{ $t('filters') }}</span>
                <div class="h-6 w-6 rounded-full flex flex-row justify-center items-center cursor-pointer" @click="closeModal()">
                    <i class="mdi mdi-close text-white text-xs"></i>
                </div>
            </div>
            <div class="flex-1 flex flex-col justify-start items-start">
                <span class="text-white text-sm">{{ $t('period') }}</span>
                <div class="h-8 w-full">
                    <div class="h-full rounded-lg flex flex-row justify-between items-center">
                        <el-select v-model="period" :placeholder="$t('select')" size="mini" class="w-full h-full">
                            <el-option
                            v-for="item in periods"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                            class="text-sm text-dfont font-semibold">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- <span class="text-white text-sm mt-2">Lugar</span>
                <div class="h-8 w-full mb-2">
                    <div class="h-full w-full rounded-lg flex flex-row justify-between items-center">
                        <el-select v-model="typePlace" :placeholder="$t('selectALocation')" size="mini" class="w-full h-full">
                            <el-option
                            v-for="item in places"
                            :key="item.value+item.name"
                            :label="item.name"
                            :value="item.value"
                            class="w-full">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <All v-if="typePlace" :model="typePlace" :immediate="false" :data.sync="topplaces" v-slot="{data:dataPlace, loading:loadingplaces}" ref="refplacess">

                    <div v-if="!loadingplaces" class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center">
                            <el-select v-model="place" :placeholder="$t('select')" size="mini" filterable class="w-full h-full">
                                <el-option
                                v-for="item in dataPlace"
                                :key="item.Id"
                                :label="item.Name || item.idLink"
                                :value="item.Id"
                                class="w-full">
                                </el-option>
                                <el-option
                                :key="'all'"
                                :label="'Todos'"
                                :value="null"
                                class="w-full">
                                </el-option>
                            </el-select>

                        </div>
                    </div>
                </All> -->

                <span class="text-white text-sm mt-2">{{ $t('indicator') }}</span>
                <All model="Groupdimensions" :immediate="true" :data.sync="topindicators" v-slot="{ data: dataindicators, loading: loadingindicators}" >
                    <div class="h-8 w-full">
                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center">
                            <el-select v-model="indicator" :placeholder="$t('select')" size="mini" class="w-full h-full">
                                <el-option
                                v-for="item in dataindicators"
                                :key="item.Key"
                                :label="translate(item.Name)"
                                :value="item.Key"
                                class="w-full" />
                            </el-select>
                        </div>
                    </div>
                </All>
            </div>
            <div class="h-10 bg-red flex flex-row justify-center items-center rounded-xl cursor-pointer" @click="applyfilters()" ref="hola">
                <span class="text-white font-semibold text-sm">{{ $t('apply') }}</span>
            </div>
        </div>
    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';

export default {
    props: ['expanded'],
    components: {
        All
    },
    data() {
        return {
            periods: [
                { 
                    name: this.$t("monthly"),
                    value: 'month'
                },
                { 
                    name: this.$t("quarterly"),
                    value: 'quarter'
                },
                { 
                    name: this.$t("annual"),
                    value: 'year'
                }
            ],
            period: null,
            places: [
                { 
                    name: 'Continente',
                    value: 'Continenttops'
                },
                { 
                    name: 'Pais',
                    value: 'Countrytops'
                },
                { 
                    name: 'Provincias',
                    value: 'Provincetops'
                }
            ],
            typePlace: null,
            topplaces: null,
            place: null,
            topindicators: null,
            indicator: null
        }
    },
    methods: {
        translate(word) {
            switch (word) {
                case 'Precio medio':
                    return this.$t("averagePrice")
                    break;
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Margen':
                    return this.$t("margin")
                    break;
                case 'Metros cuadrados':
                    return this.$t("squareMeters")
                    break;
                default:
                    return word;
            }
        },
        closeModal() { this.$emit('closemodal') },
        applyfilters() {
            actions.setVideowallPeriod(this.period)
            // actions.setVideowallTypePlace(this.typePlace)
            // actions.setVideowallPlace(this.place)
            // if(this.place != null){
            //     for (let index = 0; index < this.places.length; index++) {
            //         if(this.places[index].value == this.typePlace){
            //             actions.setVideowallTypePlaceName(this.places[index].name)
            //         }
            //     }
            //     for (let index = 0; index < this.topplaces.length; index++) {
            //         if(this.topplaces[index].Id == this.place){
            //             actions.setVideowallPlaceName(this.topplaces[index].Name)
            //         }
            //     }
            // } else {
            //     switch (this.typePlace) {
            //         case 'Continenttops':
            //             actions.setVideowallTypePlaceName('Todos los Continentes')
            //             break;
            //         case 'Countrytops':
            //             actions.setVideowallTypePlaceName('Todos los Paises')
            //             break;
            //         case 'Provincetops':
            //             actions.setVideowallTypePlaceName('Todas las Provincias')
            //             break;
            //     }
            //     actions.setVideowallPlaceName(null)
            // }
            actions.setVideowallIndicator(this.indicator)
            for (let index = 0; index < this.topindicators.length; index++) {
                if (this.topindicators[index].Key == this.indicator) { actions.setVideowallIndicatorName(this.topindicators[index].Name) }
            }
            this.closeModal();
        }
    },
    watch: {
        // typePlace(n,o){
        //     setTimeout(() => {
        //         if(o){
        //             this.place = null
        //         }
        //         this.$refs['refplacess'].request();
        //     }, 100);
        // }
    },
    created() {
        this.period = state.videowallPeriod
        this.typePlace = state.videowallTypePlace
        this.place = state.videowallPlace
        this.indicator = state.videowallIndicator
    }
}
</script>