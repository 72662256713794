<template>

    <div v-if="guid" class="h-auto">
        <All model="VideowallCompany" :immediate="true" :query="querycompany" v-slot="{data:data, loading: loadingcompany}" ref="videowallcompany">
            <div v-if="!loadingcompany" class="h-full w-full absolute top-0 left-0 z-40 flex flex-row justify-center items-center pb-6 pt-10" style="backdrop-filter:blur(5px)">
                <div class="h-full w-full mx-4 rounded-xl bg-videowall_module shadow_box p-4 flex flex-col">
                    <div class="h-auto flex flex-row justify-start items-center">
                        <div class="h-6 w-6 rounded-full bg-videowall_box flex flex-row justify-center items-center mr-2 cursor-pointer" @click="companymodal()">
                            <i class="mdi mdi-chevron-left text-white text-lg"></i>
                        </div>
                        <span class="text-lg text-white font-semibold">{{ companySelected.Name }}</span>
                        <i class="mdi mdi-arrow-collapse text-videowall_gray ml-auto text-lg cursor-pointer" @click="companymodal()"></i>
                    </div>
                    <All model="Companydetails" :immediate="true" :query="querycompany" v-slot="{data: datadetail, loading: loadingdetail}" ref="companydetails">
                        <div class="flex-1 flex flex-row justify-between items-center">
                            <All model="Companyinfo" :immediate="true" ref="companyinfo" :query="querycompany" :data.sync="companydate"
                                v-slot="{data: info2, loading: infoloading2}" />
                            <div v-if="companydate" class="h-full w-1/4 p-1 flex flex-col">
                                <bubbles-group-facturation :data="data" :modal="true" :date="companydate.LastSaleDate" />
                                <div class="h-32 w-full rounded-xl bg-videowall_box my-2"></div>
                                <div class="h-40 w-full p-1">
                                    <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                        <div class="h-8 flex flex-row justify-center items-center px-2">
                                            <span class="font-semibold text-md text-white">{{ $t('activeClients') }}</span>
                                        </div>
                                        <div class="flex-1 flex flex-row">
                                            <div class="h-full w-1/3 flex flex-col justify-center items-center">
                                                <piechart :data="[['nuevos',datadetail.BubbleClient.New] , ['Recurrentes', datadetail.BubbleClient.YearActive-datadetail.BubbleClient.New]]"
                                                    :videowall="true" />
                                        
                                            </div>
                                            <div class="h-full w-2/3 flex flex-col justify-center items-start">
                                                <div class="pr-2 w-full">
                                                    <div class="w-full h-6 flex flex-row pr-2 justify-between items-center">
                                                        <div class="text-purple2 text-sm font-semibold">{{ $t('appellants') }} </div>
                                                        <div class="text-sm text-white">{{ datadetail.BubbleClient.YearActive-datadetail.BubbleClient.New | reduceBigNumbers(0) }}</div>
                                                    </div>
                                                    <div class="w-full h-6 flex flex-row pr-2 justify-between items-center">
                                                        <div  class="text-turquesa text-sm font-semibold">{{ $t('new') }}  {{ year }}</div>
                                                        <div class="text-sm text-white">{{ datadetail.BubbleClient.New | reduceBigNumbers(0)}}</div>
                                                    </div>
                                                    <div class="w-full h-6 flex flex-row pr-2 mt-1 justify-between items-center border-t border-white">
                                                        <div class="text-sm font-semibold text-white">{{ $t('total') }} </div>
                                                        <div class="text-sm text-white">{{ datadetail.BubbleClient.YearActive | reduceBigNumbers(0) }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="companydate" class="h-full w-1/4 p-1 flex flex-col">
                                <bubbles-group-metros :data="data" :modal="true" :date="companydate.LastSaleDate" />
                                <div class="h-32 w-full rounded-xl bg-videowall_box my-2"></div>
                                <div class="h-40 w-full p-1">
                                    <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                        <div class="h-8 flex flex-row justify-center items-center px-2">
                                            <span class="font-semibold text-md text-white">{{$t('orders')}}</span>
                                        </div>
                                        <div class="flex-1 flex flex-row justify-center items-center">
                                            <div class="h-full w-full flex flex-row">
                                                <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                    <span class="text-sm text-videowall_gray">{{$t('orders')}} {{$t('day')}}</span>
                                                    <span class="text-md text-white font-semibold">{{datadetail.BubblePendingOrder.Day | reduceBigNumbers(2)}} €</span>
                                                    <span class="text-md text-white font-semibold mb-1">{{datadetail.BubblePendingOrder.DayMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
                                                </div>
                                                <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                    <span class="text-sm text-videowall_gray ">{{$t('portfolio')}} {{$t('orders')}}</span>
                                                    <span class="text-md text-white font-semibold">{{datadetail.BubblePendingOrder.Accumulated | reduceBigNumbers(2)}} €</span>
                                                    <span class="text-md text-white font-semibold">{{datadetail.BubblePendingOrder.AccumulatedMeters | reduceBigNumbers(2)}} m<sup>2</sup></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="companydate" class="h-full w-1/4 p-1 flex flex-col">
                                <bubbles-group-preciomedio  :data="data" :modal="true" :module="true" :date="companydate.LastSaleDate"/>
                                <div class="h-32 w-full rounded-xl bg-videowall_box my-2"></div>
                                <div class="h-40 w-full p-1">
                                    <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                        <div class="h-8 flex flex-row justify-center items-center px-2">
                                            <span class="font-semibold text-md text-white">{{$t('loadingOrders')}} <small>({{$t('today')}})</small></span>
                                        </div>
                                        <div class="flex-1">
                                            <div class="flex-1 flex flex-col">
                                                <div class="h-16 w-full flex flex-row mb-auto">
                                                    <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                        <span class="text-sm text-videowall_gray">{{ $t('totalsOC') }}</span>
                                                        <span class="text-md text-white font-semibold">{{ datadetail.BubbleOrders.OperativeOrdersToday | reduceBigNumbers(2) }} €</span>
                                                    </div>
                                                    <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                                        <span class="text-sm text-videowall_gray ">{{ $t('nextDayOC') }}</span>
                                                        <span class="text-md text-white font-semibold">{{ datadetail.BubbleOrders.OperativeOrdersNextDay | reduceBigNumbers(2) }} €</span>
                                                    </div>
                                                </div>
                                                <div class="h-auto w-ful flex flex-row justify-between items-center px-4">

                                                    <div class="font-bold text-videowall_gray text-sm">{{ $t('totalsOC') }}</div>
                                                    <div v-if="datadetail.BubbleOrders.OperativeOrdersTodayTomorrow" class="text-sm font-bold text-white">
                                                        {{ datadetail.BubbleOrders.OperativeOrdersTodayTomorrow | reduceBigNumbers(0) }}
                                                    </div>
                                                    <div v-else class="text-sm font-bold text-white">-</div>
                                                </div>
                                                <div class="h-auto w-full flex flex-row justify-between items-center px-4">
                                                    <div class="font-bold text-videowall_gray text-sm">{{ $t('lastSynchronization') }}</div>
                                                    <div v-if="datadetail.BubbleOrders.SyncTime" class="text-sm font-bold text-white">
                                                        {{ datadetail.BubbleOrders.SyncTime | moment('HH:mm') }}
                                                    </div>
                                                    <div v-else class="text-sm font-bold text-white">-</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="companydate" class="h-full w-1/4 p-1 flex flex-col">
                                <bubbles-group-margen :data="data" :modal="true" :module="true" :date="companydate.LastSaleDate" />
                                <div class="h-32 w-full rounded-xl bg-videowall_box my-2"></div>
                                <div class="h-40 w-full p-1">
                                    <div class="h-full w-full rounded-xl bg-videowall_box flex flex-col">
                                        <div class="h-8 flex flex-row justify-center items-center px-2">
                                            <span class="font-semibold text-md text-white">{{ $t('deliveryNotesDay') }}</span>
                                        </div>
                                        <div class="flex-1 flex flex-col">
                                                <div class="flex-1 w-full flex flex-row">
                                                    <div class="h-full w-full flex flex-col justify-center items-center">
                                                        <span class="text-sm text-videowall_gray">{{ $t('current') }}</span>
                                                        <span class="text-md text-white font-semibold">{{ datadetail.BubbleDeliveryNoteOrders.Day | reduceBigNumbers(2) }} €</span>
                                                        <span class="text-md text-white font-semibold">
                                                            {{ datadetail.BubbleDeliveryNoteOrders.DayMeters | reduceBigNumbers(2) }} m<sup>2</sup>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="h-8 mt-auto w-full flex flex-row justify-between items-center px-4">
                                                    <div class="font-bold text-videowall_gray text-sm">{{ $t('lastSynchronization') }}</div>
                                                    <div class="text-sm font-bold text-white">{{ datadetail.BubbleDeliveryNoteOrders.SyncTime | moment('HH:mm') }}h</div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </All>
                </div>
            </div>
            <div v-else class="h-full w-full absolute top-0 left-0 z-40 flex flex-row justify-center items-center pb-6 pt-10" style="backdrop-filter:blur(5px)">
                <div class="h-full w-full mx-4 rounded-xl bg-videowall_module shadow_box p-4 flex flex-col justify-center items-center relative">
                    <loader :loading="loadingcompany" />
                </div>
            </div>
        </All>
    </div>

</template>

<script>
import { All,Request } from '@/api/components';
import { state } from '@/store';
import bubblesGroupFacturation from './bubblesGroupFacturation.vue';
import bubblesGroupMetros from './bubblesGroupMetros.vue';
import bubblesGroupPreciomedio from './bubblesGroupPreciomedio.vue';
import bubblesGroupMargen from './bubblesGroupMargen.vue';
import piechart from './piechartvideowall.vue';
import loader from '../videowall/loaderVideowall.vue';

export default {
    components: {
        All,
        Request,
        bubblesGroupFacturation,
        bubblesGroupMetros,
        bubblesGroupPreciomedio,
        bubblesGroupMargen,
        piechart,
        loader
    },
    data() {
        return {
            companydate: null
        }
    },
    methods:{
        companymodal() { this.$emit('companymodal') }
    },
    computed: {
        companySelected() { return state.videowallCompany },
        querycompany() {
            return {
                period: this.period,
                Dimension: this.dimension,
                Company: state.videowallCompany.Guid,
                IndustrialGroup: state.videowallIndustrialGroup
            }
        },
        refreshTime() { return state.refreshTime },
        guid() { return state.videowallCompany },
        period() { return state.videowallPeriod },
        dimension() { return state.videowallIndicator }
    },
    watch: {
        period() {
            this.$refs['videowallcompany'].request();
            this.$refs['companydetails'].request();
        },
        dimension() {
            this.$refs['videowallcompany'].request();
            this.$refs['companydetails'].request();
        }
    },
    mounted() {
        setInterval(() => {
            this.$refs['videowallcompany'].request();
            this.$refs['companydetails'].request();
        }, this.refreshTime);
    }
}
</script>
