<template>
    <highcharts  class="chart w-full h-full" :options="options" ref="chartId" id="chartId"></highcharts>
</template>


<script>
export default {
    props:['data'],
    data(){
        return{
            
        }
    },
    methods: {
    },
    computed:{

        options(){
            
            if(!(this.data)){
                return
            }
            return{
                chart:{
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                type: 'pie',
                margin: [0, 0, 0, 0]
                },
                title:{
                    text: ''
                },
                credits:{
                    enabled:false
                },
                plotOptions:{
                    pie: {
                        dataLabels: {
                            enabled: true,
                            distance: -30,
                            format:'{percentage:.2f}%',
                            style:{
                                fontSize:'12px',
                                textOutline:'none',
                                color:'#393742'
                            },
                            enabled: false
                        },
                        marker:{
                            enabled: false,
                            radius: 0
                        },
                        startAngle: 0,
                        endAngle: 360,
                        innerSize: '1%',
                        center: ['50%', '50%'],
                        size: '100%',
                        animation: true,
                        borderWidth: 0,
                        borderColor: '#F8F8F8'
                    }
                },
                tooltip:{
                    enabled: false
                },
                series: [{
                    name: '',
                    data:this.data,
                    colors: ['#68BFB9', '#CF97E3', '#EBBD41', '#43CFE8', '#AFA9A9']
                }]
            }
        }

    },
    mounted(){
        
    },
    watch:{
        
    }
}
</script>
