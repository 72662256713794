<template>

    <div class="h-auto w-1/5 p-2">
        <div class="h-auto w-full rounded-lg flex flex-col p-2 bg-videowall_box" :class="{'cursor-pointer': selected}">
            <div class="h-auto max-h-12 flex flex-row">
                <div class="h-full w-2/12 flex justify-center items-center my-auto">
                    <div class="h-6 w-6 rounded-full overflow-hidden">
                        <vue-initials-img :name="data.Name" class="h-6 w-6"/>
                    </div>
                </div>
                <div class="h-full w-9/12 flex flex-col justify-center items-start pl-2">
                    <span class="font-semibold text-md text-white">{{$t('zone')}} {{data.Name || 'Zona Sin Nombre'}}</span>
                </div>
                <!-- <div class="h-full w-1/12 flex flex-row justify-center items-center">
                    <i class="mdi mdi-arrow-right font-semibold text-white hidden"></i>
                </div> -->
            </div>

            <div class="flex-1 flex flex-col justify-between py-2 mt-1">
                <div class="h-auto mb-2">
                <double-chart :current="data.PreviewPercent" :prev="data.PastYearPercent"
                    v-if="dimensionName != 'Margen' && dimensionName != 'Precio medio'" />
                </div>
                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                    <span class="text-sm font-bold text-white">{{data.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="text-sm" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                    <span v-if="dimensionName == 'Precio medio'" class="text-sm" :class="'text-font_gray-'+mode">{{$t('averagePrice')}}</span>
                    <span v-if="dimensionName == 'Margen'" class="text-sm" :class="'text-font_gray-'+mode">{{$t('margin')}}</span>
                    <span class="text-sm font-bold text-white">{{data.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                    <span class="text-sm font-bold text-white">{{data.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('scope')}}</span>
                    <span class="text-sm font-bold text-white">{{data.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-sm" :class="'text-font_gray-'+mode">Real {{yearant}}</span>
                    <span class="text-sm font-bold text-white">{{data.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-sm" :class="'text-font_gray-'+mode">{{$t('variation')}} {{periodo}}</span>
                    <span class="text-sm font-bold text-white"><span v-if="data.MonthVariation >= 0">+</span>{{data.MonthVariation | reduceBigNumbers()}} <span v-html="unit3"></span></span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { state, actions } from '@/store';
import { All, Request } from '@/api/components';
import doubleChart from '@/components/videowall/doubleChart.vue';

export default {
    props:['data', 'comercialSelected', 'key2', 'dimensionName', 'rol'],
    components:{
        All,
        Request,
        doubleChart
    },
    data() {
        return {
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            message: null
        }
    },
    methods:{
        onSuccess() {
            this.message = null
            this.$message({
            message: this.$t("messageSentSuccessfully"),
            type: 'success'
            });
        },
        onError(error) {
            this.$message.error(this.$t("theMessageCouldNotBeSent"))
        }
    },
    computed:{
        unit() {
            let unit = '€'
            if (state.videowallIndicator === 1) { unit = 'm<sup>2</sup>' }
            if (state.videowallIndicator === 3) { unit = '%' }
            return unit
        },
        unit3() {
            let unit = '%'
            if (state.videowallIndicator === 2) { unit = ' €/m<sup>2</sup>' }
            if (state.videowallIndicator === 3) { unit = '' }
            return unit
        },
        user() {
            return state.user
        },
        selected() {
            if (state.user.rol.Name === 'Propiedad' || state.user.rol.Name === 'Gerente') { return true }
            else {
                for (let index = 0; index < state.user.relation.length; index++) {
                    if (state.user.relation[index].zonelink.IdZone.toUpperCase() == this.data.Id){ return true }
                    else { return false }
                }
            }
        },
        form() {
            return {
                message: this.message,
                Company: state.idCompany,
                SaleType: state.saleTypeSelected,
                // IdUser: this.data.Id
                Zone: this.data.Id
            }
        },
        permisions() {
            if (state.user.rol.Name == 'Gerente') {
                if (state.user.email == 'imilian@pamesa.com') { return true }
                else
                    if (state.user.email == 'msebastia@tauceramica.com') { return true }
                    else
                        if (state.user.email == 'jpiquer@geotiles.com') { return true }
                        else { return false }
            }
            else { return false }
        },
        dimension() {
            return state.videowallIndicator
        }
    },
    mounted() {
    }
}
</script>