<template>
    
    <div class="h-full w-full absolute top-0 left-0 z-20 flex flex-row justify-center items-center" style="backdrop-filter:blur(5px)">

        <All model="VideowallGroup" :immediate="false" :query="query" v-slot="{data,loading}" ref="allmodal">
        
            <div v-if="!loading" class="w-full mx-4 rounded-xl bg-videowall_module shadow_box p-4 flex flex-col">

                <div class="h-auto flex flex-row justify-start items-center">

                    <div class="h-6 w-6 rounded-full bg-videowall_box flex flex-row justify-center items-center mr-2 cursor-pointer" @click="groupmodal()">
                        <i class="mdi mdi-chevron-left text-white text-lg"></i>
                    </div>

                    <span class="text-lg text-white font-semibold">{{$t('group')}}</span>

                    <i class="mdi mdi-arrow-collapse text-videowall_gray ml-auto text-lg cursor-pointer" @click="groupmodal()"></i>

                </div>

                <All model="Groupinfo" :immediate="true" :query="query" :data.sync="infodata" v-slot="{data:info,loading:infoloading}" ref="all">

                    <div v-if="!infoloading && info" class="h-auto">

                        <div class="flex-1 flex flex-row justify-between items-center">

                            <div class="h-full w-1/4 p-1 flex flex-col">

                                <bubbles-group-facturation :data="data" :date="info.LastSaleDate" :modal="true"></bubbles-group-facturation>

                                <div class="mt-2 h-32 rounded-xl bg-videowall_box mb-2"></div>
                            
                            </div>

                            <div class="h-full w-1/4 p-1 flex flex-col">

                                <bubbles-group-metros :data="data" :date="info.LastSaleDate" :modal="true"></bubbles-group-metros>

                                <div class="mt-2 h-32 rounded-xl bg-videowall_box mb-2"></div>
                            
                            </div>

                            <div class="h-full w-1/4 p-1 flex flex-col">
                            
                                <bubbles-group-preciomedio  :data="data" :date="info.LastSaleDate" :modal="true" :module="true"></bubbles-group-preciomedio>

                                <div class="mt-2 h-32 rounded-xl bg-videowall_box mb-2"></div>
                            
                            </div>

                            <div class="h-full w-1/4 p-1 flex flex-col">
                            
                                <bubbles-group-margen :data="data" :date="info.LastSaleDate" :modal="true" :module="true"></bubbles-group-margen>

                                <div class="mt-2 h-32 rounded-xl bg-videowall_box mb-2"></div>
                            
                            </div>

                        </div>

                        <div class="h-16 flex flex-row">

                            <div class="h-full w-1/2 mr-2">
                                <order-bubbles :modal="true" :date="info.LastSaleDate"></order-bubbles>
                            </div>

                            <div class="h-full w-1/2">
                                <delivery-notes-bubbles :modal="true"></delivery-notes-bubbles>
                            </div>

                        </div>

                    </div>

                </All>

            </div>

            <div v-else class="h-32 w-32 relative">

                <loader :loading="loading"></loader>

            </div>

        </All>

    </div>

</template>

<script>
import { All,Request } from '@/api/components';
import { state,actions } from '@/store';
import bubblesGroupFacturation from './bubblesGroupFacturation.vue';
import bubblesGroupMetros from './bubblesGroupMetros.vue';
import bubblesGroupPreciomedio from './bubblesGroupPreciomedio.vue';
import bubblesGroupMargen from './bubblesGroupMargen.vue';
import loader from './loaderVideowall.vue';
import orderBubbles from './orderBubbles.vue';
import deliveryNotesBubbles from './deliveryNotesBubbles.vue';

export default {
    components:{
        bubblesGroupFacturation,
        All,
        Request,
        bubblesGroupMetros,
        bubblesGroupPreciomedio,
        bubblesGroupMargen,
        loader,
        orderBubbles,
        deliveryNotesBubbles
    },
    data(){
        return{
            yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
            infodata:null
        }
    },
    methods:{
        groupmodal(){
            this.$emit('groupmodal')
        }
    },
    computed:{
        period(){
            return state.videowallPeriod
        },
        query(){
            return{
                period: this.period
            }
        }
    },
    watch:{
        period(){
            this.$refs['allmodal'].request();
        }
    },
    mounted(){
        this.$refs['allmodal'].request();
    }
}
</script>