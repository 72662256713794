<template>

    <All model="VideowallGroup" :immediate="false" :query="query" v-slot="{data,loading}" ref="allinfo">
        <div v-if="!loading && data" class="h-full rounded-xl pb-2 flex flex-col">
            <div class="h-6 flex flex-row justify-between items-center px-2">
                <span class="text-lg font-semibold text-white">{{ $t('group') }}</span>
                <!-- <span class="text-lg font-semibold text-white">{{$t('group')}} <small class="text-videowall_gray">({{yesterday_general}})</small></span> -->
                <i class="mdi mdi-arrow-expand text-videowall_gray text-lg cursor-pointer" @click="expandgroup()"></i>
            </div>
            <div class="flex-1 mt-4 flex flex-col">
                <All model="Groupinfo" :immediate="true" :query="query" v-slot="{data: info, loading: infoloading}" ref="all">
                    <div v-if="!infoloading" class="">
                        <bubbles-group-facturation :data="data" :date="info.LastSaleDate" :modal="false" :optionselected="optionselected" />
                        <bubbles-group-metros :data="data" :date="info.LastSaleDate" :modal="false" :optionselected="optionselected" />
                        <bubbles-group-preciomedio :data="data" :date="info.LastSaleDate" :modal="false" :optionselected="optionselected" />
                        <bubbles-group-margen :data="data" :date="info.LastSaleDate" :modal="false" :optionselected="optionselected" />
                        <order-bubbles :modal="false" :date="info.LastSaleDate" />
                        <delivery-notes-bubbles :data="data" :date="info.LastSaleDate" :modal="false" />
                    </div>
                </All>
            </div>
        </div>
        <div v-else class="h-full relative"><loader :loading="loading" /></div>

    </All>

</template>

<script>
import { All,Request } from '@/api/components';
import { state,actions } from '@/store';
import bubblesGroupFacturation from './bubblesGroupFacturation.vue';
import bubblesGroupMetros from './bubblesGroupMetros.vue';
import bubblesGroupPreciomedio from './bubblesGroupPreciomedio.vue';
import bubblesGroupMargen from './bubblesGroupMargen.vue';
import loader from './loaderVideowall';
import orderBubbles from './orderBubbles.vue';
import deliveryNotesBubbles from './deliveryNotesBubbles.vue';

export default {
    components: {
        All,
        Request,
        bubblesGroupFacturation,
        bubblesGroupMetros,
        bubblesGroupPreciomedio,
        bubblesGroupMargen,
        loader,
        orderBubbles,
        deliveryNotesBubbles
    },
    data() {
        return {
            datasolidgauge: {
                actual: 60,
                prev: 40
            },
            yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
            yesterday_general: this.$moment().subtract(1, 'days').format("DD MMMM YYYY"),
        }
    },
    methods:{
        expandgroup() { this.$emit('expandgroup') },
        changeindicator(option) {
            switch (option) {
                case 'facturacion':
                    actions.setVideowallIndicator(0)
                    actions.setVideowallIndicatorName(this.$t("billing"))
                    break;
                case 'metros':
                    actions.setVideowallIndicator(1)
                    actions.setVideowallIndicatorName(this.$t("squareMeters"))
                    break;
                case 'preciomedio':
                    actions.setVideowallIndicator(2)
                    actions.setVideowallIndicatorName(this.$t("averagePrice"))
                    break;
                case 'margen':
                    actions.setVideowallIndicator(3)
                    actions.setVideowallIndicatorName(this.$t("margin"))
                    break;
            }
        }
    },
    computed: {
        query() {
            return {
                period: state.videowallPeriod,
                // Place: this.videowallplace,
                // PlaceId: state.videowallPlace
            }
        },
        period() { return state.videowallPeriod },
        optionselected() { return state.videowallIndicator },
        videowallplace() {
            switch (state.videowallTypePlace) {
                case 'Continenttops':
                    return this.$t("continent")
                    break;
                case 'Countrytops':
                    return this.$t("country")
                    break;
                case 'Provincetops':
                    return this.$t("province")
                    break;
            }
        },
        place() { return state.videowallTypePlace },
        placeid() { return state.videowallPlace },
        indicator() { return state.videowallIndicator },
        refreshTime() { return state.refreshTime; }
    },
    watch: {
        period() {
            setTimeout(() => {
                this.$refs['allinfo'].request();
                this.$refs['all'].request();
            }, 100);
        },
        // place(){
            //     if(this.placeid == null){
            //         this.$refs['all'].request();
            //     }
        // },
        // placeid(){
            //     this.$refs['all'].request();
        // },
        indicator() {
            // this.$refs['all'].request();
        }
    },
    mounted() {
        this.$refs['allinfo'].request();
        this.$refs['all'].request();
        setInterval(() => {
            this.$refs['allinfo'].request();
            this.$refs['all'].request();
        }, this.refreshTime);
    }
}
</script>
