<template>

    <div class="rounded-xl" :class="{'h-148.8': optionselected === 1
        || modal, 'h-20': optionselected !== 1, 'mt-4':modal, 'mt-1': !modal}" style="transition: all .3s" @click="changeOption(1)">
        <div v-if="modal" class="h-auto flex flex-col rounded-xl bg-videowall_box py-1">
            <div class="h-8 w-full flex flex-row justify-center items-center px-2">
                <span class="text-white text-md font-semibold text-left">{{ $t('squareMeters') }}  <small class="text-videowall_gray">({{ date | moment('DD MMM YY') }})</small></span>
            </div>
            <div class="h-12 flex flex-row">
                <div class="h-full w-1/4 flex flex-col justify-center items-center">
                    <span class="text-sm text-videowall_gray">{{ $t('day') }}</span>
                    <span class="text-md text-white font-semibold">{{data.total.BubbleMeter.Yesterday | reduceBigNumbers()}} m<sup>2</sup></span>
                </div>
                <div class="h-full w-1/4 flex flex-col justify-center items-center">
                    <span class="text-sm text-videowall_gray">{{$t('accumulated')}}</span>
                    <span class="text-md text-white font-semibold">{{data.total.BubbleMeter.Month | reduceBigNumbers()}} m<sup>2</sup></span>
                </div>
                <div class="h-full w-1/4 flex flex-col justify-center items-center">
                    <span class="text-sm text-videowall_gray">{{$t('deliveryNotes')}}</span>
                    <span class="text-md text-white font-semibold">{{data.total.BubbleMeter.DeliveryNote | reduceBigNumbers()}} m<sup>2</sup></span>
                </div>
                <div class="h-full w-1/4 flex flex-col justify-center items-center">
                    <span class="text-sm text-videowall_gray">{{$t('variation')}}</span>
                    <span class="text-md font-semibold"
                        :class="{'text-bad': data.total.BubbleMeter.MonthVariation < 0, 'text-good': data.total.BubbleMeter.MonthVariation >= 0}">
                        {{ data.total.BubbleMeter.MonthVariation | reduceBigNumbers() }}%
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="h-20 flex flex-row justify-start items-center  bg-videowall_box rounded-xl">
            <div class="h-full w-1/5 flex flex-col justify-center items-start px-2">
                <span class="text-white text-md font-semibold text-left">{{$t('squareMeters')}} <br><small class="text-videowall_gray">({{ date | moment('DD MMM YY') }})</small></span>
            </div>
            <div class="h-full w-1/5 flex flex-col justify-center items-center">
                <span class="text-sm text-videowall_gray">{{ $t('day') }}</span>
                <span class="text-md text-white font-semibold">{{ data.total.BubbleMeter.Yesterday | reduceBigNumbers() }} m<sup>2</sup></span>
            </div>
            <div class="h-full w-1/5 flex flex-col justify-center items-center">
                <span class="text-sm text-videowall_gray">{{ $t('accumulated') }}</span>
                <span class="text-md text-white font-semibold">{{ data.total.BubbleMeter.Month | reduceBigNumbers() }} m<sup>2</sup></span>
            </div>
            <div class="h-full w-1/5 flex flex-col justify-center items-center">
                <span class="text-sm text-videowall_gray">{{$t('deliveryNotes')}}</span>
                <span class="text-md text-white font-semibold">{{data.total.BubbleMeter.DeliveryNote | reduceBigNumbers()}} m<sup>2</sup></span>
            </div>
            <div class="h-full w-1/5 flex flex-col justify-center items-center">
                <span class="text-sm text-videowall_gray">{{$t('variation')}}</span>
                <span class="text-md font-semibold"
                    :class="{'text-bad': data.total.BubbleMeter.MonthVariation < 0, 'text-good': data.total.BubbleMeter.MonthVariation >= 0}">
                    {{ data.total.BubbleMeter.MonthVariation | reduceBigNumbers() }}%
                </span>
            </div>
        </div>
        <div v-if="optionselected === 1 || modal" class="h-130 overflow-hidden bg-videowall_module" :class="{'pb-2': modal}">
            <div class="h-1/2 w-full flex flex-row overflow-hidden py-2">
                <div class="w-full h-full flex flex-row rounded-xl bg-videowall_box px-6">
                    <div class="h-full w-1/5 flex flex-col justify-end items-center pb-2">
                        <span class="text-sm font-semibold text-videowall_gray">Var. {{ periodo }}</span>
                        <span class="text-md font-semibold text-white"><span v-if="total.MonthVariation >= 0">+</span>{{ total.MonthVariation | reduceBigNumbers() }}%</span>
                    </div>
                    <div class="h-full w-3/5 flex flex-col justify-center items-center">
                        <div class="h-32 w-32 rounded-full absolute flex flex-col justify-center items-center">
                            <span class="text-sm font-semibold text-purple2">{{$t('scope')}}</span>
                            <span class="text-md text-white font-semibold">{{total.MonthPreview | reduceBigNumbers()}}  m<sup>2</sup></span>
                            <span class="text-sm font-semibold text-blue">{{ $t('real') }} {{ prevperiod }}</span>
                            <span class="text-md text-white font-semibold">{{total.PastYear | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <solidgaugevideowall :data="{actual:total.PreviewPercent, prev:total.PastYearPercent, dif:(total.PastYearPercent - total.PreviewPercent + total.PreviewPercent)}" />
                    </div>
                    <div class="h-full w-1/5 flex flex-col justify-end items-center pb-2">
                        <!-- <span class="text-sm font-semibold text-videowall_gray">Impagos</span>
                        <span class="text-md font-semibold text-bad">{{total.UnpaidValue | reduceBigNumbers()}}</span> -->
                    </div>
                </div>
            </div>
            <div class="h-1/2 w-full flex flex-row overflow-hidden">
                <div class="h-full w-1/2 flex flex-col mr-1">
                    <div class="w-full h-full flex flex-col rounded-xl bg-videowall_box pb-2">
                        <div class="flex-1 flex flex-col justify-center items-center">
                            <div class="h-32 w-32 rounded-full absolute flex flex-col justify-center items-center">
                                <span class="text-sm text-videowall_gray font-semibold">{{ $t('national') }}</span>
                                <span class="text-sm font-semibold text-purple2">{{ $t('scope') }}</span>
                                <span class="text-md text-white font-semibold">{{ nacional.MonthPreview | reduceBigNumbers() }} m<sup>2</sup></span>
                                <span class="text-sm font-semibold text-blue">{{ $t('real') }} {{ prevperiod }}</span>
                                <span class="text-md text-white font-semibold">{{nacional.PastYear | reduceBigNumbers()}} m<sup>2</sup></span>
                            </div>
                            <solidgaugevideowall :data="{actual:nacional.PreviewPercent, prev:nacional.PastYearPercent,
                                dif:(nacional.PastYearPercent - nacional.PreviewPercent + nacional.PreviewPercent)}" />
                        </div>
                        <div class="h-10 flex flex-row justify-between items-center">
                            <div class="h-full w-1/2 mx-auto flex flex-col justify-center items-center">
                                <span class="text-sm font-semibold text-videowall_gray">Var. {{ periodo }}</span>
                                <span class="text-md font-semibold text-white"><span v-if="nacional.MonthVariation >= 0">+</span>{{ nacional.MonthVariation | reduceBigNumbers() }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-full w-1/2 flex flex-col ml-1">
                    <div class="w-full h-full flex flex-col rounded-xl bg-videowall_box pb-2">
                        <div class="flex-1 flex flex-col justify-center items-center">
                            <div class="h-32 w-32 rounded-full absolute flex flex-col justify-center items-center">
                                <span class="text-sm text-videowall_gray font-semibold">{{ $t('exportCapitalLetter') }}</span>
                                <span class="text-sm font-semibold text-purple2">{{$t('scope')}}</span>
                                <span class="text-md text-white font-semibold">{{exportacion.MonthPreview | reduceBigNumbers()}} m<sup>2</sup></span>
                                <span class="text-sm font-semibold text-blue">{{$t('real')}} {{prevperiod}}</span>
                                <span class="text-md text-white font-semibold">{{exportacion.PastYear | reduceBigNumbers()}} m<sup>2</sup></span>
                            </div>
                            <solidgaugevideowall :data="{actual: exportacion.PreviewPercent, prev: exportacion.PastYearPercent,
                                dif:(exportacion.PastYearPercent - exportacion.PreviewPercent + exportacion.PreviewPercent)}" />
                        </div>
                        <div class="h-10 flex flex-row justify-between items-center">
                            <div class="h-full w-1/2 mx-auto flex flex-col justify-center items-center">
                                <span class="text-sm font-semibold text-videowall_gray">Var. {{periodo}}</span>
                                <span class="text-md font-semibold text-white"><span v-if="exportacion.MonthVariation >= 0">+</span>{{exportacion.MonthVariation | reduceBigNumbers() }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="modal" class="h-32 py-2">
            <basic-line-chart chartId="graph" :data="total.Historical"></basic-line-chart>
        </div>

    </div>

</template>

<script>
import solidgaugevideowall from '@/components/solidgaugevideowall.vue';
import { state,actions } from '@/store';
import basicLineChart from '../basiclinechart.vue';

export default {
    props: ['data', 'modal', 'date'],
    components: {
        solidgaugevideowall,
        basicLineChart
    },
    data() {
        return {
            datasolidgauge: {
                actual:60,
                prev:40
            },
            yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
        }
    },
    methods: {
        changeOption(option) {
            if (state.videowallIndicator !== option) {
                actions.setVideowallIndicator(option)
                actions.setVideowallIndicatorName(this.$t("squareMeters"))
            }
        }
    },
    computed: {
        nacional() { return this.data['24C8C714394211EA966B005056AEAA71'].BubbleMeter },
        exportacion() { return this.data['24C85D60394211EA966B005056AEAA71'].BubbleMeter },
        total() { return this.data.total.BubbleMeter },
        prevperiod() {
            switch (state.videowallPeriod) {
                case 'month':
                    return this.$moment().subtract(1, 'years').format("YYYY")
                    break;
                case 'quarter':
                    return 'Trim.' + this.$moment().subtract(1, 'years').format("YYYY")
                    break;
                case 'year':
                    return this.$moment().subtract(1, 'years').format("YYYY")
                    break;
            }
        },
        periodo() {
            switch (state.videowallPeriod) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },
        optionselected() { return state.videowallIndicator }
    }
}
</script>
